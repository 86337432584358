import React, { FormEvent, useEffect, useRef } from 'react';
import { TextField, styled } from '@mui/material';
import { Department } from 'types/department';
import { DepartmentValidation } from './validation/departmentValidation';

const Form = styled('form')({
  rowGap: 15,
  display: 'flex',
  maxWidth: 400,
  flexDirection: 'column',
});

interface DepartmentFormProps {
  handleValidation(e: FormEvent<HTMLFormElement>): void;
  department: Department | null;
  handleChange(index: keyof Department, value: any): void;
  validation: DepartmentValidation;
}

const DepartmentForm: React.FC<DepartmentFormProps> = ({ department, handleChange, handleValidation, validation }) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Form onSubmit={handleValidation}>
      <TextField
        autoFocus
        ref={inputs.name}
        value={department?.name}
        onChange={e => handleChange('name', e.target.value)}
        error={!!validation.name}
        helperText={validation.name}
        placeholder="Digite o nome do departamento"
        label="Nome"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  );
};

export default DepartmentForm;
