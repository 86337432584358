import React, { useState } from 'react';
import UnityProvider from './hooks/useAbcUnits';
import AbcUnityPage from './AbcUnityPage';
import { FilterMore } from 'components/filter-more/FilterMore';
import { endOfDay, startOfDay } from 'date-fns';
import { useSelector } from 'store/selector';

export interface AbcUnitsFilterParams extends FilterMore {
  initialDate: Date | null;
  finalDate: Date | null;
  selectedBranchId: number;
  productId: number;
  typeSale: string;
}

const CurveAbcUnity: React.FC = () => {
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [filter, setFilter] = useState<AbcUnitsFilterParams>({
    initialDate: startOfDay(new Date()),
    finalDate: endOfDay(new Date()),
    selectedBranchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    productId: 0,
    typeSale: 'T',
    categoryIds: [],
    subCategoryIds: [],
    madeBy: '',
    segmentIds: [],
    unitIds: [],
    subGroupIds: [],
    manufacturerIds: [],
  });

  function handleChangeFilter(index: keyof AbcUnitsFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }
  return (
    <UnityProvider filter={filter}>
      <AbcUnityPage filter={filter} handleChangeFilter={handleChangeFilter} />
    </UnityProvider>
  );
};

export default CurveAbcUnity;
