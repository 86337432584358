import React, { FormEvent } from 'react';
import { Button, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';
import { DepartmentFilter } from './hook/useFetchDepartments';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  flex: 1,
  gap: 10,
  gridTemplateColumns: '200px 100px',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 100px',
  },
}));

interface Props {
  filter: DepartmentFilter;
  handleChangeFilter: (index: keyof DepartmentFilter, value: any) => void;
  fetchDepartments: (filter: DepartmentFilter) => void;
  loading: boolean;
}

const DepartmentFilterBox: React.FC<Props> = ({ filter, loading, handleChangeFilter, fetchDepartments }) => {
  function handleSearch(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    fetchDepartments(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={handleSearch}>
        <TextField
          fullWidth
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.term}
          onChange={e => handleChangeFilter('term', e.target.value)}
        />

        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default DepartmentFilterBox;
