import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Department } from 'types/department';
import DepartmentItemModule from './DepartmentItemModule';

type DepartmentListModuleProps = {
  departments: Department[];
};

const DepartmentListModule: React.FC<DepartmentListModuleProps> = ({ departments }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {departments.map(department => (
            <DepartmentItemModule key={department.id} department={department} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default DepartmentListModule;
