import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { BranchFinancialTicket, CustomerFinancialTicket, FinancialTicket } from 'types/financialReportList';
import { FinancialTicketFilterParams } from '../FinancialTicketReport';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { BillSetting } from 'types/billSetting';
import { api } from 'services/api';

export type SalesReportTotal = {
  total: string;
  feesTotal: string;
  value: string;
  trafficTicket: string;
};

export type UseFetchFinancialTicketReport = {
  handleSearch(searchValue: string): void;
  settings: BillSetting[];
  tickets: FinancialTicket[];
  loading: boolean;
  agroupedBranchesTicket: BranchFinancialTicket[];
  agroupedCustomersTicket: CustomerFinancialTicket[];
  loadingCustomerInfo: boolean;
  handleClickCnpjField(document: string): void;
  customerTickets: FinancialTicket[];
  onCloseCustomerTickets(): void;
};

export function useFetchFinancialTicketReport(filter: FinancialTicketFilterParams): UseFetchFinancialTicketReport {
  const [tickets, setTickets] = useState<FinancialTicket[]>([]);
  const [customerTickets, setCustomerTickets] = useState<FinancialTicket[]>([]);
  const [agroupedBranchesTicket, setAgroupedBranchesTicket] = useState<BranchFinancialTicket[]>([]);
  const [agroupedCustomersTicket, setAgroupedCustomersTicket] = useState<CustomerFinancialTicket[]>([]);
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(false);
  const [settings, setSettings] = useState<BillSetting[]>([]);

  useEffect(() => {
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => setSettings(response.data))
      .catch(err => console.error(err));
  }, []);

  function handleClickCnpjField(document: string) {
    if (!h2iApi) return;

    setLoadingCustomerInfo(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get(`/api/relboletos`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_pesquisa: filter.typeDate,
          situacao: filter.situation,
          filtro: '',
        },
      })
      .then(response => {
        const _response: FinancialTicket[] = response.data.relboleto;

        if (!_response) {
          setCustomerTickets([]);
          return;
        }

        setCustomerTickets(
          _response
            .filter(item => item.cnpj_cpf === document)
            .map(item => {
              const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
              const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
              const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();

              item.formattedTotal = moneyFormat(item.valor_total);
              item.formattedValue = moneyFormat(item.valor);
              item.formattedTrafficTicket = moneyFormat(item.multa);
              item.formattedFees = moneyFormat(item.juros);
              item.formattedDueDate = dueDate;
              item.formattedReleaseDate = releaseDate;
              item.formattedPayDate = payDate;

              return item;
            }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingCustomerInfo(false));
  }

  const handleSearch = useCallback(
    async (searchValue: string) => {
      if (!h2iApi) return;

      setLoading(true);

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      const params = {
        data_ini: formattedInitialDate,
        data_fim: formattedFinalDate,
        id_banco: filter.bank_billet_setting_id || '',
        id_filial: filter.branchId || '',
        filtro: searchValue,
        tipo_pesquisa: filter.typeDate,
        situacao: filter.situation,
      };

      const fetchTickets = await h2iApi.get(`/api/relboletos`, {
        params,
      });

      const fetchBranchesTickets = await h2iApi.get(`/api/getboletosfilial`, {
        params,
      });

      const fetchCustomersTickets = await h2iApi.get(`/api/getboletoscliente`, {
        params,
      });

      Promise.all([fetchTickets, fetchBranchesTickets, fetchCustomersTickets])
        .then(([responseTickets, responseBranchesTickets, responseCustomersTickets]) => {
          const _response: FinancialTicket[] = responseTickets.data.relboleto;
          const _responseBranches: BranchFinancialTicket[] = responseBranchesTickets.data.relboletofilial;
          const _responseCustomers: CustomerFinancialTicket[] = responseCustomersTickets.data.relboleto;
          if (!_response) {
            setAgroupedCustomersTicket([]);
            setAgroupedBranchesTicket([]);
            setTickets([]);
            return;
          }
          setTickets(
            _response.map(item => {
              const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
              const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
              const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();

              item.formattedTotal = moneyFormat(item.valor_total);
              item.formattedValue = moneyFormat(item.valor);
              item.formattedTrafficTicket = moneyFormat(item.multa);
              item.formattedFees = moneyFormat(item.juros);
              item.formattedDueDate = dueDate;
              item.formattedReleaseDate = releaseDate;
              item.formattedPayDate = payDate;

              return item;
            }),
          );

          setAgroupedBranchesTicket(agroupedByBranch(_response, _responseBranches));
          setAgroupedCustomersTicket(agroupedByCustomer(_response, _responseCustomers));
        })
        .finally(() => setLoading(false));
    },
    [h2iApi, filter],
  );

  function agroupedByBranch(
    financialTickets: FinancialTicket[],
    branchTickets: BranchFinancialTicket[],
  ): BranchFinancialTicket[] {
    const groupedBranchTickets: BranchFinancialTicket[] = [];

    financialTickets.forEach(ticket => {
      const branchTicketIndex = groupedBranchTickets.findIndex(
        branchTicket => branchTicket.id_filial === ticket.id_filial,
      );

      if (branchTicketIndex !== -1) {
        const branchTicket = groupedBranchTickets[branchTicketIndex];
        branchTicket.valor_total += ticket.valor_total;

        if (!ticket.data_pag) {
          if (parsePtBRDate(ticket.vencimento) >= new Date()) {
            branchTicket.a_receber += ticket.valor_total;
          } else {
            branchTicket.vencidos += ticket.valor_total;
          }
        } else {
          branchTicket.pagos += ticket.valor_total;
        }

        groupedBranchTickets[branchTicketIndex] = branchTicket;
      } else {
        const newBranchTicket: BranchFinancialTicket = {
          id_filial: ticket.id_filial,
          filial: ticket.filial,
          limite_credito: branchTickets.filter(branch => branch.id_filial === ticket.id_filial)[0].limite_credito, // Defina o valor apropriado ou calcule conforme necessário
          valor_total: ticket.valor_total,
          a_receber: !ticket.data_pag && parsePtBRDate(ticket.vencimento) >= new Date() ? ticket.valor_total : 0,
          vencidos: !ticket.data_pag && parsePtBRDate(ticket.vencimento) < new Date() ? ticket.valor_total : 0,
          pagos: ticket.data_pag ? ticket.valor_total : 0,
        };
        groupedBranchTickets.push(newBranchTicket);
      }
    });

    return groupedBranchTickets;
  }

  function agroupedByCustomer(
    financialTickets: FinancialTicket[],
    customerTickets: CustomerFinancialTicket[],
  ): CustomerFinancialTicket[] {
    const groupedCustomerTickets: CustomerFinancialTicket[] = [];

    financialTickets.forEach(ticket => {
      const customerTicketIndex = groupedCustomerTickets.findIndex(
        customerTicket => customerTicket.cnpj_cpf === ticket.cnpj_cpf,
      );

      if (customerTicketIndex !== -1) {
        const customerTicket = groupedCustomerTickets[customerTicketIndex];
        customerTicket.valor_total += ticket.valor_total;

        if (!ticket.data_pag) {
          if (new Date(ticket.vencimento) >= new Date()) {
            customerTicket.a_receber += ticket.valor_total;
          } else {
            customerTicket.vencidos += ticket.valor_total;
          }
        } else {
          customerTicket.pagos += ticket.valor_total;
        }

        groupedCustomerTickets[customerTicketIndex] = customerTicket;
      } else {
        const newCustomerTicket: CustomerFinancialTicket = {
          cnpj_cpf: ticket.cnpj_cpf,
          limite_credito: customerTickets.filter(customer => customer.cnpj_cpf === ticket.cnpj_cpf)[0].limite_credito, // Defina o valor apropriado ou calcule conforme necessário
          valor_total: ticket.valor_total,
          a_receber: !ticket.data_pag && new Date(ticket.vencimento) >= new Date() ? ticket.valor_total : 0,
          vencidos: !ticket.data_pag && new Date(ticket.vencimento) < new Date() ? ticket.valor_total : 0,
          pagos: ticket.data_pag ? ticket.valor_total : 0,
        };
        groupedCustomerTickets.push(newCustomerTicket);
      }
    });

    console.log(groupedCustomerTickets);
    return groupedCustomerTickets;
  }

  function onCloseCustomerTickets() {
    setCustomerTickets([]);
  }

  return {
    handleSearch,
    settings,
    tickets,
    loading,
    agroupedBranchesTicket,
    agroupedCustomersTicket,
    loadingCustomerInfo,
    handleClickCnpjField,
    customerTickets,
    onCloseCustomerTickets,
  };
}
