import { TableTemplate } from 'types/tableTemplate';

export const patrimonyTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 110,
  },
  {
    id: 'formattedFilial',
    description: 'Filial',
    originalId: 'formattedFilial',
    width: 140,
  },
  {
    id: 'name',
    description: 'Descrição',
    originalId: 'name',
    width: 230,
  },
  {
    id: 'responsible',
    description: 'Responsável',
    originalId: 'responsible',
    width: 150,
  },
  {
    id: 'serial_number',
    description: 'Número de série',
    originalId: 'serial_number',
    width: 150,
  },
  {
    id: 'serial_note_number',
    description: 'Número NF compra',
    originalId: 'serial_note_number',
    width: 150,
  },
  {
    id: 'is_active',
    description: 'Ativo',
    originalId: 'is_active',
    width: 80,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Criado em',
    originalId: 'created_at',
    width: 100,
  },
];
