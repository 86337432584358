import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography, styled } from '@mui/material';
import { AgroupedBranchReserve } from '../../dashboard/hooks/useFetchReserveDashboard';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

type SubgroupItemTableProps = {
  branch: AgroupedBranchReserve;
  moreBranch: AgroupedBranchReserve | null;
  setMoreBranch: Dispatch<SetStateAction<AgroupedBranchReserve | null>>;
};

const BranchAnalysisItemTable: React.FC<SubgroupItemTableProps> = ({ branch, moreBranch, setMoreBranch }) => {
  const { tableTemplate } = useTable();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreBranch(moreBranch?.filial === branch.filial ? null : branch);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreBranch?.filial === branch.filial ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : (
            <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {branch[item.id]}
              </Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default BranchAnalysisItemTable;
