import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { Button, TextField, styled } from '@mui/material';
import { ProjectCostFormContent } from 'types/project';
import { ProjectCostValidation } from './validation/projectContactValidation';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';

const Form = styled('form')(props => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: 900,
  flex: 0,
  gap: 10,
  alignItems: 'baseline',
  marginBottom: 30,
  '& > button': {
    flexShrink: 0,
  },
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
}));

interface ProjectCostFormProps {
  handleSubmit(data: ProjectCostFormContent): Promise<void>;
  validation: ProjectCostValidation;
}

const ProjectCostForm: React.FC<ProjectCostFormProps> = ({ handleSubmit: defaultHandleSubmit, validation }) => {
  const [description, setDescription] = useState('');
  const [value, setValue] = useState('');
  const [referenceDate, setReferenceDate] = useState<Date | null>(new Date());

  const inputs = {
    description: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    referenceDate: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    defaultHandleSubmit({
      description,
      value,
      referenceDate: referenceDate as Date,
    })
      .then(() => {
        setDescription('');
        setValue('');
        setReferenceDate(new Date());
        inputs.description.current?.focus();
      })
      .catch(err => console.error(err));
  }

  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <TextField
        label="Descrição"
        placeholder="Descrição do custo"
        margin="normal"
        autoFocus
        value={description}
        onChange={event => setDescription(event.target.value)}
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        fullWidth
      />

      <TextField
        label="Valor (R$)"
        placeholder="Valor (R$)"
        margin="normal"
        value={value}
        onChange={event => setValue(event.target.value)}
        inputRef={inputs.value}
        error={!!validation.value}
        helperText={validation.value}
        fullWidth
        type="number"
        inputMode="decimal"
      />

      <DesktopDatePicker
        label="Data de referência"
        value={referenceDate}
        onChange={date => setReferenceDate(date)}
        inputRef={inputs.referenceDate}
        textFieldProps={{
          fullWidth: true,
          margin: 'normal',
          error: !!validation.referenceDate,
          helperText: validation.referenceDate,
          placeholder: 'Cargo do contato',
        }}
      />

      <Button type="submit" size="small" variant="contained" color="primary">
        inserir
      </Button>
    </Form>
  );
};

export default ProjectCostForm;
