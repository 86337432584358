import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const CustomTabs = styled(Tabs)({
  backgroundColor: '#fff',
  '& > div > div': {
    gap: 5,
  },
  '& button': {
    minHeight: 30,
  },
  '& > div > span': {
    bottom: 6,
  },
});

interface AbcProductTabsProps {
  tab: string;
  onChange(tab: string): void;
}

const AbcProductTabs: React.FC<AbcProductTabsProps> = ({ onChange, tab }) => {
  return (
    <CustomTabs variant="scrollable" value={tab} onChange={(e, value) => onChange(value)}>
      <Tab value="category" label="Categoria" />
      <Tab value="branch" label="Filial" />
      <Tab value="product" label="Produto" />
      <Tab value="unity" label="Unidade" />
      <Tab value="segment" label="Segmento" />
      <Tab value="subgroup" label="Subgrupo" />
      <Tab value="manufacturer" label="Fabricante" />
      <Tab value="order_by" label="Pedido por" />
      <Tab value="customer" label="Cliente" />
      <Tab label="Formas de pagamento" value="payment_method" />
    </CustomTabs>
  );
};

export default AbcProductTabs;
