import { TableTemplate } from 'types/tableTemplate';

export const branchTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 40,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 110,
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'qtd',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'formattedTotalValue',
    description: 'Valor Total',
    originalId: 'valor_total',
    width: 110,
    dataType: 'number',
  },
];
