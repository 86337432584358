import React, { FormEvent } from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';
import { PatrimonyFilter } from './hook/useFetchPatrimonies';
import SelectBranch from 'components/select-branches/SelectBranch';
import { Department } from 'types/department';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  flex: 1,
  gap: 10,
  gridTemplateColumns: '200px 200px 200px 200px 100px 100px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    rowGap: 15,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    '& > button': {
      display: 'flex',
      alignSelf: 'end',
    },
  },
}));

interface Props {
  departments: Department[];
  filter: PatrimonyFilter;
  handleChangeFilter: (index: keyof PatrimonyFilter, value: any) => void;
  fetchPatrimonies: (filter: PatrimonyFilter) => void;
  loading: boolean;
}

const PatrimonyFilterBox: React.FC<Props> = ({
  departments,
  filter,
  loading,
  handleChangeFilter,
  fetchPatrimonies,
}) => {
  function handleSearch(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    fetchPatrimonies(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={handleSearch}>
        <SelectBranch branch_id={filter.branch_id} handleChange={value => handleChangeFilter('branch_id', value)} />

        <TextField
          fullWidth
          label="Descrição"
          placeholder="Digite a descrição"
          autoFocus
          value={filter.term}
          onChange={e => handleChangeFilter('term', e.target.value)}
        />

        <TextField
          fullWidth
          label="Responsável"
          placeholder="Digite o responsável"
          autoFocus
          value={filter.responsible}
          onChange={e => handleChangeFilter('responsible', e.target.value)}
        />

        <TextField
          select
          label="Departamento"
          placeholder="Selecione o departamento"
          value={filter.department_id}
          onChange={e => handleChangeFilter('department_id', e.target.value)}
          fullWidth
        >
          <MenuItem value="all">Todos</MenuItem>
          {departments.map(department => (
            <MenuItem value={department.id} key={department.id}>
              {department.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          label="Ativo"
          placeholder="Ativo"
          select
          value={filter.is_active}
          onChange={e => handleChangeFilter('is_active', e.target.value)}
        >
          <MenuItem value={1}>Sim</MenuItem>
          <MenuItem value={0}>Não</MenuItem>
          <MenuItem value="">Todos</MenuItem>
        </TextField>

        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default PatrimonyFilterBox;
