import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import { useFinancialTicket } from './hooks/useFinancialTicket';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import SelectBranch from 'components/select-branches/SelectBranch';

type FinancialTicketFilterProps = {
  onExited(): void;
};

const FinancialTicketFilter: React.FC<FinancialTicketFilterProps> = ({ onExited }) => {
  const { filter, handleChangeFilter, settings } = useFinancialTicket();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div style={{ display: 'grid', rowGap: 15, width: '100%', zoom: '90%' }}>
        <SelectBranch branch_id={filter.branchId} handleChange={e => handleChangeFilter('branchId', e)} />

        <TextField
          select
          fullWidth
          placeholder="Selecionar layout..."
          value={filter.bank_billet_setting_id}
          onChange={e => handleChangeFilter('bank_billet_setting_id', parseInt(e.target.value))}
          label="Layout"
        >
          {settings.map(bill => (
            <MenuItem key={bill.id} value={bill.id}>
              {bill.bank_name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          value={filter.typeDate}
          onChange={e => handleChangeFilter('typeDate', e.target.value)}
          select
          label="Tipo da data"
        >
          <MenuItem value={'V'}>Vencimento</MenuItem>
          <MenuItem value={'L'}>Lançamento</MenuItem>
        </TextField>
        <TextField
          fullWidth
          value={filter.situation}
          onChange={e => handleChangeFilter('situation', e.target.value)}
          select
          label="Situação"
        >
          <MenuItem value={'A'}>Apropriados</MenuItem>
          <MenuItem value={'N'}>Não apropriados</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </TextField>
        <KeyboardDatePicker
          label="Data inicial"
          value={filter.initialDate}
          onChange={date => handleChangeFilter('initialDate', date)}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={filter.finalDate}
          onChange={date => handleChangeFilter('finalDate', date)}
        />
      </div>
    </DialogInput>
  );
};

export default FinancialTicketFilter;
