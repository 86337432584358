import React, { useCallback, useState, useEffect, useRef } from 'react';
import ResellerItem from './ResellerItem';
import { TextField, Autocomplete } from '@mui/material';
import { H2iCustomer } from 'types/h2iCustomer';
import ResellerAutoCompleteAdornment from './ResellerAutoCompleteAdornment';
import { useApp } from 'hooks/app';
import styles from './autocomplete.module.css';

let timer: NodeJS.Timeout;

interface AutocompleteFromProps {
  onSelect(customer: H2iCustomer | null): void;
  label: string;
  placeholder: string;
  customer: H2iCustomer | null;
  helperText: string;
  error: boolean;
  branchId?: number;
  disabled?: boolean;
}

const ResellerAutoComplete: React.FC<AutocompleteFromProps> = ({
  onSelect,
  label,
  placeholder,
  customer,
  helperText,
  error,
  branchId,
  disabled = false,
}) => {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState<H2iCustomer[]>([]);
  const [customers, setCustomers] = useState<H2iCustomer[]>([]);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!error) {
      return;
    }

    inputRef.current?.focus();
  }, [error]);

  const handleSelectReseller = useCallback(
    (reseller: H2iCustomer) => {
      onSelect(reseller);
      setFiltered([]);
    },
    [onSelect],
  );

  useEffect(() => {
    setFiltered(customers);
  }, [customers]);

  const findCustomers = useCallback(
    async (term: string, branchId: number | string) => {
      setLoading(true);
      h2iApi
        ?.get(`/api/getclientescadastrados?filtro=${term}&id_filial=${branchId}`)
        .then(response => {
          setCustomers(response.data);
        })
        .finally(() => setLoading(false))
        .catch(err => console.error(err));
    },
    [h2iApi],
  );

  const clearSelected = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  function handleChange(value: string) {
    clearTimeout(timer);

    if (value.length < 3) {
      return;
    }

    timer = setTimeout(() => findCustomers(value, branchId || ''), 400);
  }

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      componentsProps={{
        popper: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
      }}
      id="customer-autocomplete"
      open={filtered.length > 0}
      options={filtered}
      value={customer}
      fullWidth
      disabled={disabled}
      filterOptions={customers => customers}
      getOptionLabel={customer => `${customer.nome}`}
      renderOption={(props, option) => (
        <ResellerItem handleSelectReseller={handleSelectReseller} value={customer} reseller={option} {...props} />
      )}
      renderInput={params => (
        <TextField
          {...params}
          disabled={disabled}
          inputRef={inputRef}
          label={label}
          placeholder={placeholder}
          onChange={event => handleChange(event.target.value)}
          autoFocus
          error={error}
          helperText={helperText}
          onBlur={() => setFiltered([])}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <ResellerAutoCompleteAdornment loading={loading} selected={customer} clearSelected={clearSelected} />
            ),
          }}
        />
      )}
    />
  );
};

export default ResellerAutoComplete;
