import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { Patrimony } from 'types/patrimony';

const CustomListItemButton = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
});

type PatrimonyItemModuleProps = {
  patrimony: Patrimony;
};

const PatrimonyItemModule: React.FC<PatrimonyItemModuleProps> = ({ patrimony }) => {
  function handleClick() {
    history.push(`/patrimonies/${patrimony.id}`);
  }

  return (
    <CustomListItemButton onClick={handleClick}>
      <Grid>
        <Typography variant="caption">ID:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.id}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Nome:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.name}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Responsável:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.responsible}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Número de série:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.serial_number}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Número NF Compra:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.serial_note_number}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Obs:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.observation}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Criado em:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {patrimony.formattedCreatedAt}
        </Typography>
      </Grid>
    </CustomListItemButton>
  );
};

export default PatrimonyItemModule;
