import { Dispatch, SetStateAction, useState } from 'react';
import { Patrimony } from 'types/patrimony';
import * as yup from 'yup';

export type PatrimonyValidation = {
  name?: string;
  serial_note_number?: string;
  serial_number?: string;
  department_id?: string;
  branch_id?: string;
};

type UsePatrimonyValidation = [
  PatrimonyValidation,
  Dispatch<SetStateAction<PatrimonyValidation>>,
  (patrimony: Patrimony) => Promise<void>,
];

export function usePatrimonyValidation(): UsePatrimonyValidation {
  async function handleValidation(patrimony: Patrimony) {
    const schema = yup.object().shape({
      serial_note_number: yup.string().required('O número da NF de compra é obrigatório'),
      serial_number: yup.string().required('O número de série é obrigatório'),
      name: yup.string().required('O nome do patrimônio é obrigatório'),
      department_id: yup.string().required('O departamento é obrigatório'),
      branch_id: yup.string().required('A filial é obrigatória'),
    });

    try {
      await schema.validate(patrimony);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<PatrimonyValidation>({});
  return [validation, setValidation, handleValidation];
}
