import { TableTemplate } from 'types/tableTemplate';

export const paymentTableTemplate: TableTemplate[] = [
  {
    id: 'forma_pagamento',
    description: 'Forma',
    originalId: 'forma_pagamento',
    width: 200,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'pedido_por',
    description: 'Pedido por',
    originalId: 'pedido_por',
    width: 350,
  },
];
