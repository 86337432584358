import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { useProject } from 'pages/projects/registration/hook/useProject';
import React, { useEffect, useState, useMemo } from 'react';
import { ProjectActionContextProvider } from './hooks/useProjectActions';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ActionListTable from './list/table/ActionListTable';
import ActionListModule from './list/module/ActionListModule';
import ProjectContactForm from './form/ProjectActionForm';
import { addProjectAction } from 'pages/projects/registration/reducer/action';
import { ProjectAction, ProjectActionFormContent } from 'types/project';
import { useProjectActionValidation } from './validation/projectActionValidation';
import { useMessaging } from 'hooks/messaging';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProjectActions: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { handleOpen } = useMessaging();
  const { project, dispatch } = useProject();
  const [filteredActions, setFilteredActions] = useState<ProjectAction[]>([]);
  const [selectedProjectAction, setSelectedProjectAction] = useState<ProjectAction | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useProjectActionValidation();

  useEffect(() => {
    setFilteredActions(project.actions);
  }, [project.actions]);

  function handleSort(index: string) {
    const _contacts = sort(index, filteredActions);
    setFilteredActions(_contacts);
  }

  async function handleValidation(data: ProjectActionFormContent) {
    setValidation({});

    await validate(data);
    if (filteredActions.length === 5) {
      handleOpen('Você atingiu o limite de ações para este projeto');
      return;
    }
    handleSubmit(data);
  }

  function handleSubmit(data: ProjectActionFormContent) {
    dispatch(addProjectAction(data));
  }

  return (
    <Container>
      <ProjectContactForm validation={validation} handleSubmit={handleValidation} />

      {filteredActions.length === 0 ? (
        <NoData message="Nenhuma ação para mostrar" />
      ) : (
        <ProjectActionContextProvider value={{ selectedProjectAction, setSelectedProjectAction }}>
          {displayMode === 'list' ? (
            <ActionListTable actions={filteredActions} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ActionListModule actions={filteredActions} />
          )}
        </ProjectActionContextProvider>
      )}
    </Container>
  );
};

export default ProjectActions;
