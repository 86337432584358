import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import { useCallback, useState, useEffect } from 'react';
import { api } from 'services/api';
import { Department } from 'types/department';

export interface DepartmentFilter {
  term: string;
}

export function useFetchDepartments() {
  const [filter, setFilter] = useState<DepartmentFilter>({
    term: '',
  });
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [orderedIndex, sort] = useTableOrder();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [filteredDepartments, setFilteredDepartments] = useState<Department[]>([]);

  useEffect(() => {
    setFilteredDepartments(departments);
  }, [departments]);

  const fetchDepartments = useCallback(
    (filter: DepartmentFilter) => {
      setLoading(true);

      api
        .get(`/hr-departments`, {
          params: { ...filter, page: page + 1, rows },
        })
        .then(response => {
          setDepartments(
            response.data.items.map(item => ({
              ...item,
              formattedCreatedAt: format(parseISO(item.created_at), 'P', { locale: ptBR }),
            })),
          );
          setTotal(response.data.total);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    },
    [rows, page],
  );

  function handleSort(index: string) {
    const filtered = sort(index, filteredDepartments);
    setFilteredDepartments(filtered);
  }

  function handleChangeFilter(index: keyof DepartmentFilter, value: any) {
    setFilter(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  async function onChangePage(page: number, filter: DepartmentFilter) {
    await setPage(page);
  }

  async function onChangeRow(rows: number, filter: DepartmentFilter) {
    await setRows(rows);
  }

  return {
    handleSort,
    loading,
    orderedIndex,
    filteredDepartments,
    fetchDepartments,
    handleChangeFilter,
    filter,
    total,
    onChangePage,
    onChangeRow,
  };
}
