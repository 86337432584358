import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, styled } from '@mui/material';
import { Branch } from 'types/branch';
import { useBranches } from 'providers/BranchesProviders';

type SelectOthersBranchProps = {
  branch_id: number;
  accounting?: boolean;
  handleChange(value: number): void;
  viewAllBranches?: boolean;
  loading?: boolean;
  setBranch?(branch: Branch): void;
  selectStyle?: React.CSSProperties;
  helperText?: string;
  className?: string;
} & SelectProps;

const CustomSelect = styled(Select)({
  minWidth: 150,
  height: '100%',
});

const SelectOthersBranch: React.FC<SelectOthersBranchProps> = ({
  branch_id,
  handleChange,
  loading = false,
  accounting = false,
  setBranch,
  selectStyle,
  viewAllBranches = true,
  label = 'Selecione a filial',
  helperText,
  className,
  ...rest
}) => {
  const { financialBranches, accountingBranches } = useBranches();

  const branches = accounting ? accountingBranches : financialBranches;

  return (
    <FormControl className={className}>
      <InputLabel>{label}</InputLabel>
      <CustomSelect
        style={selectStyle}
        label={label}
        value={branch_id}
        disabled={loading}
        onChange={e => handleChange(e.target.value as number)}
        {...rest}
      >
        {viewAllBranches && <MenuItem value={1001}>TODAS AS FILIAIS</MenuItem>}

        {branches.map(branch => (
          <MenuItem onClick={() => (setBranch ? setBranch(branch) : undefined)} key={branch.id} value={branch.id}>
            {branch.nome}
          </MenuItem>
        ))}

        {branch_id === 999 && <MenuItem value={999}>Nenhuma filial selecionada</MenuItem>}
      </CustomSelect>
      <FormHelperText sx={{ color: '#d32f2f' }}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectOthersBranch;
