import { styled, Typography } from '@mui/material';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CommercialDashboard, CommercialDashboardTotal } from 'types/commercialDashboard';
import CommercialDashboardListTable from './list/waiting/table/CommercialDashboardListTable';
import CommercialDashboardTotalListTable from './list/total/table/CommercialDashboardListTable';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import CommercialDashboardListModule from './list/waiting/module/CommercialDashboardListModule';
import CommercialDashboardTotalListModule from './list/total/module/CommercialDashboardListModule';

const Container = styled('div')(({ theme }) => ({
  gap: 30,
  flex: 1,
  padding: 15,
  backgroundColor: '#fff',
  display: 'grid',
  maxWidth: '100%',
  gridTemplateColumns: '1.4fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const BoxTitle = styled('div')({
  backgroundColor: '#30519f',
  padding: '5px 10px',
  maxHeight: 30,
});

const Box = styled('div')({
  border: '1px solid #30519f',
  display: 'grid',
});

const ContainerList = styled('div')(({ theme }) => ({
  maxHeight: '100vh',
  overflow: 'auto',
  height: '100%',
  marginTop: 10,
}));

interface CommercialDashboardContentProps {
  orders: CommercialDashboard[];
  groupOrdersByPedidoPor: () => CommercialDashboardTotal[];
  loading: boolean;
  setCustomerContacted: Dispatch<SetStateAction<boolean>>;
}

const CommercialDashboardContent: React.FC<CommercialDashboardContentProps> = ({
  orders,
  loading,
  groupOrdersByPedidoPor,
  setCustomerContacted,
}) => {
  const [filteredOrders, setFilteredOrders] = useState<CommercialDashboard[]>([]);
  const [filteredOrdersTotal, setFilteredOrdersTotal] = useState<CommercialDashboardTotal[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode] = useDisplayMode();

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  useEffect(() => {
    setFilteredOrdersTotal(groupOrdersByPedidoPor());
  }, [groupOrdersByPedidoPor]);

  function handleSort(index: string) {
    const p = sort(index, filteredOrders);
    setFilteredOrders(p);
  }

  function handleSortTotal(index: string) {
    const p = sort(index, filteredOrdersTotal);
    setFilteredOrdersTotal(p);
  }

  return (
    <Container>
      <Box>
        <BoxTitle>
          <Typography color="#fff" variant="body2">
            Relação de orçamento pendentes
          </Typography>
        </BoxTitle>

        <ContainerList>
          <PaginationProvider>
            {loading ? (
              <TableLoading />
            ) : orders.length === 0 ? (
              <NoData message="Nenhum orçamento encontrado" />
            ) : displayMode === 'list' ? (
              <CommercialDashboardListTable
                setCustomerContacted={setCustomerContacted}
                handleSort={handleSort}
                orderedIndex={orderedIndex}
                orders={filteredOrders}
              />
            ) : (
              <CommercialDashboardListModule orders={filteredOrders} setCustomerContacted={setCustomerContacted} />
            )}
            <Pagination count={filteredOrders.length} />
          </PaginationProvider>
        </ContainerList>
      </Box>
      <Box>
        <BoxTitle>
          <Typography color="#fff" variant="body2">
            Total por pedido por
          </Typography>
        </BoxTitle>

        <ContainerList>
          <PaginationProvider>
            {loading ? (
              <TableLoading />
            ) : orders.length === 0 ? (
              <NoData message="Nenhum orçamento encontrado" />
            ) : displayMode === 'list' ? (
              <CommercialDashboardTotalListTable
                handleSort={handleSortTotal}
                orderedIndex={orderedIndex}
                orders={filteredOrdersTotal}
              />
            ) : (
              <CommercialDashboardTotalListModule orders={filteredOrdersTotal} />
            )}
            <Pagination count={filteredOrdersTotal.length} />
          </PaginationProvider>
        </ContainerList>
      </Box>
    </Container>
  );
};

export default CommercialDashboardContent;
