import React, { useState } from 'react';
import { styled, Typography } from '@mui/material';
import ActionItemTable from './ActionItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { makeStyles } from '@mui/styles';
import { ACTIONS_TABLE_TEMPLATE } from '../actionsTableTemplate';
import { ProjectAction } from 'types/project';
import ProjectCostsListMenu from '../ProjectActionsListMenu';
import { useProjectActions } from '../../hooks/useProjectActions';

interface StyleProps {
  gridTemplateColumns: string;
}

const ContentContainer = styled('div')<StyleProps>(props => ({
  margin: '0px 75px',
  borderTop: `1px solid ${props.theme.palette.primary.main}`,
  display: 'grid',
  gridTemplateColumns: props.gridTemplateColumns,
  '& > div:first-child': {
    justifySelf: 'start',
  },
  '& > div': {
    justifySelf: 'center',
  },
  '& > div:last-child': {
    justifySelf: 'end',
  },
  [props.theme.breakpoints.down('lg')]: {
    margin: '0px 50px',
  },
  [props.theme.breakpoints.down('sm')]: {
    margin: '50px 0px',
    gap: 15,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderTop: 'none',
    minHeight: 350,
    width: '100%',
    flex: 1,
    borderLeft: `1px solid ${props.theme.palette.primary.main}`,
  },
}));

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

const Content = styled('div')<StyleProps>(props => ({
  display: 'grid',
  gridTemplateColumns: props.gridTemplateColumns,
  '& > div:first-child': {
    justifySelf: 'start',
  },
  '& > div': {
    justifySelf: 'center',
  },
  '& > div:last-child': {
    justifySelf: 'end',
  },
  [props.theme.breakpoints.down('sm')]: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Line = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 100,
  width: 1,
  [theme.breakpoints.down('sm')]: {
    height: 1,
    width: '100%',
  },
}));

const Box = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 150,
  padding: 10,
  border: `1px solid ${theme.palette.primary.main}`,
  [theme.breakpoints.down('lg')]: {
    width: 100,
  },
  [theme.breakpoints.down('sm')]: {
    width: 150,
  },
}));

type ActionListTableProps = {
  actions: ProjectAction[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ActionListTable: React.FC<ActionListTableProps> = ({ actions, handleSort, orderedIndex }) => {
  const { setSelectedProjectAction } = useProjectActions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  function handleClick(action: ProjectAction) {
    setSelectedProjectAction(action);
  }

  return (
    <Container>
      <ProjectCostsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <ContentContainer gridTemplateColumns={`repeat(${actions.length}, 1fr)`}>
        {actions.map((item, index) => (
          <Line key={index} />
        ))}
      </ContentContainer>
      <Content gridTemplateColumns={`repeat(${actions.length}, 1fr)`}>
        {actions.map((item, index) => (
          <Box key={index}>
            <Typography>{item.action}</Typography>
            <Typography variant="caption">{item.full_name}</Typography>
            <Typography variant="caption">{item.formattedReferenceDate}</Typography>
          </Box>
        ))}
      </Content>
    </Container>
  );
};

export default ActionListTable;
