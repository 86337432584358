import React from 'react';
import NoData from 'components/no-data/NoData';
import { styled } from '@mui/material';
import { Reserve } from 'types/reserve';
import { AgroupedBranchReserve, useFetchReserveDashboard } from './hooks/useFetchReserveDashboard';
import ReserveDashboardBranch from './branch/ReserveDashboardBranch';
import ReserveDashboardSeller from './seller/ReserveDashboardSeller';
import ReserveDashboardTotal from './ReserveDashboardTotal';

interface ReserveDashboardProps {
  reserves: Reserve[];
  branches: AgroupedBranchReserve[];
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const ReserveDashboard: React.FC<ReserveDashboardProps> = ({ reserves, branches }) => {
  const { groupedSellers } = useFetchReserveDashboard(reserves);

  return (
    <Container>
      <ReserveDashboardTotal reserves={reserves} />

      {reserves.length === 0 ? (
        <NoData message="Dashboard não disponível" />
      ) : (
        <Content>
          <ReserveDashboardBranch branches={branches} />

          <ReserveDashboardSeller sellers={groupedSellers} />
        </Content>
      )}
    </Container>
  );
};

export default ReserveDashboard;
