import React from 'react';
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import InvoiceItem from './InvoiceTableItem';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { invoicesTableTemplate } from '../../invoicesTableTemplate';
import { Invoice } from 'types/invoices';
import { useInvoices } from '../../hooks/useInvoices';
import SelectMultiOthersBranches from 'components/select-branches/SelectMultiOthersBranches';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

interface InvoiceTableListProps {
  type: string;
  selectedBranchesId: number[];
  handleChangeSelectedBranches: (branches: number[]) => void;
  filtered: Invoice[];
  invoices: Invoice[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const InvoiceTableList: React.FC<InvoiceTableListProps> = ({
  type,
  filtered,
  invoices,
  selectedBranchesId,
  handleChangeSelectedBranches,
  handleSort,
  orderedIndex,
}) => {
  const { rowsPerPage, page } = usePagination();
  const { handleSelectAll, selectAll } = useInvoices();

  const availableBranches: number[] = invoices
    .filter((item, index, self) => self.map(i => i.id_filial).indexOf(item.id_filial) === index)
    .map(item => parseInt(item.id_filial.toString()));

  return (
    <TableContainer tableTemplate={invoicesTableTemplate}>
      <TableContent>
        <TableHeader>
          {invoicesTableTemplate.map(item =>
            item.id === 'select' ? (
              <HeaderItem style={{ justifyContent: 'center' }} key={item.id}>
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  label=""
                  key={item.id}
                  control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
                />
              </HeaderItem>
            ) : item.id === 'filial' ? (
              <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
                <SelectMultiOthersBranches
                  style={{
                    height: 30,
                    width: 230,
                    fontSize: 11,
                  }}
                  labelStyle={{
                    fontSize: 12,
                  }}
                  size="small"
                  availableBranches={availableBranches}
                  branchIds={selectedBranchesId}
                  handleChange={handleChangeSelectedBranches}
                />
              </HeaderItem>
            ) : item.id === 'fornecedor' ? (
              <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
                <Typography variant="caption" color="primary">
                  {type === 'F' ? 'Funcionário' : type === 'T' ? 'Fornecedor' : 'Classificação'}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ) : (
              <HeaderItem
                className={item.dataType === 'number' ? 'numericData' : ''}
                key={item.id}
                onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ),
          )}
        </TableHeader>
        <TableBody useMaxHeight={true}>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
            <TableRow key={invoice.id}>
              <InvoiceItem invoice={invoice} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default InvoiceTableList;
