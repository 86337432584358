import React from 'react';
import BranchMoreMenu from '../BranchMoreMenu';
import BranchItemTable from './BranchItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import { AbcProductBranch } from 'types/abcProduct';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { branchTableTemplate } from '../../branchTableTemplate';
import { Typography, styled } from '@mui/material';
import { useAbcCustomers } from '../../../hooks/useAbcCustomers';

const TableRowCustom = styled('div')({
  '&.isSubgroup': {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
});

const CustomTableHeader = styled(TableHeader)({
  backgroundColor: '#eee',
});

type BranchListTableProps = {
  branches: AbcProductBranch[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const BranchListTable: React.FC<BranchListTableProps> = ({ branches, handleSort, orderedIndex, isSubgroup }) => {
  const { moreBranch } = useAbcCustomers();
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={branchTableTemplate}>
      <TableContent>
        <CustomTableHeader className={`${isSubgroup ? 'headerSubgroup' : ''}`}>
          {branchTableTemplate.map(item => (
            <div
              className={item.dataType === 'number' ? `numericData` : ''}
              key={item.id}
              onClick={() => handleSort(item.originalId)}
            >
              <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId &&
                (orderedIndex.direction === 'asc' ? (
                  <ArrowUpward color="primary" />
                ) : (
                  <ArrowDownward color="primary" />
                ))}
            </div>
          ))}
        </CustomTableHeader>
        <TableBody>
          <TableRowCustom className={`${isSubgroup ? 'isSubgroup' : ''}`}>
            {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(branch => (
              <>
                <TableRow style={{ minHeight: 10, padding: 0 }} key={branch.id_filial}>
                  <BranchItemTable isSubgroup={isSubgroup} branch={branch} />
                </TableRow>

                {moreBranch?.id_filial === branch.id_filial && <BranchMoreMenu key={branch.filial} branch={branch} />}
              </>
            ))}
          </TableRowCustom>
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default BranchListTable;
