import { TableTemplate } from 'types/tableTemplate';

export const departmentTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 110,
  },
  {
    id: 'name',
    description: 'Nome',
    originalId: 'name',
    width: 230,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Criado em',
    originalId: 'created_at',
    width: 100,
  },
];
