import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import PaginationProvider, { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import BranchAnalysisItemTable from './BranchAnalysisItemTable';
import { AgroupedBranchReserve } from '../../dashboard/hooks/useFetchReserveDashboard';
import { branchTableTemplate } from '../template/branchTableTemplate';
import { Reserve } from 'types/reserve';
import ReserveAnalysisListTable from '../../list/table/ReserveAnalysisListTable';
import useTableOrder from 'hooks/tableOrder';
import { uuidv4 } from 'helpers/uuidv4';
import Pagination from 'components/pagination/Pagination';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const Container = styled('div')({
  paddingLeft: 40,
});

type BranchAnalysisListTableProps = {
  branches: AgroupedBranchReserve[];
  reserves: Reserve[];
  handleSort(index: string): void;
  moreBranch: AgroupedBranchReserve | null;
  setMoreBranch: Dispatch<SetStateAction<AgroupedBranchReserve | null>>;
};

const BranchAnalysisListTable: React.FC<BranchAnalysisListTableProps> = ({
  moreBranch,
  setMoreBranch,
  branches,
  handleSort,
  reserves,
}) => {
  const { rowsPerPage, page } = usePagination();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<Reserve[]>([]);

  useEffect(() => {
    setFiltered(reserves);
  }, [reserves]);

  function handleSortReserve(index: string) {
    const filtered = sort(index, reserves);
    setFiltered(filtered);
  }

  return (
    <TableContainer tableTemplate={branchTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {branchTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? `numericData` : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={10} variant="caption" color="primary">
                {item.description}
              </Typography>
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight maxHeight={110}>
          {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((branch, index) => (
            <>
              <TableRow key={`${index}-${uuidv4()}`}>
                <BranchAnalysisItemTable setMoreBranch={setMoreBranch} moreBranch={moreBranch} branch={branch} />
              </TableRow>
              {moreBranch?.filial === branch.filial && (
                <Container>
                  <PaginationProvider rowsPerPage={24}>
                    <ReserveAnalysisListTable
                      key={`${branch.filial}-${uuidv4()}`}
                      reserves={filtered.filter(reserve => reserve.filial === moreBranch.filial)}
                      handleSort={handleSortReserve}
                      orderedIndex={orderedIndex}
                    />
                    <Pagination count={filtered.filter(reserve => reserve.filial === moreBranch.filial).length} />
                  </PaginationProvider>
                </Container>
              )}
            </>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default BranchAnalysisListTable;
