import React, { FormEvent, useEffect, useState } from 'react';
import InsideSaving from 'components/loading/InsideSaving';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { Patrimony } from 'types/patrimony';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import PatrimonyActions from '../PatrimonyActions';
import { usePatrimonyValidation } from '../validation/patrimonyValidation';
import PatrimonyForm from '../PatrimonyForm';
import { Department } from 'types/department';
import { Done } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

const Mobile = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const initialState: Patrimony = {
  id: '',
  name: '',
  branch_id: '',
  responsible: '',
  is_active: 1,
  observation: '',
  department_id: '',
  serial_number: '',
  department: [],
  serial_note_number: '',
};

const PatrimonyNew: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [validation, setValidation, validate] = usePatrimonyValidation();
  const [patrimony, setPatrimony] = useState<Patrimony>(initialState);
  const { handleOpen } = useMessaging();

  useEffect(() => {
    api.get('/hr-departments', { params: { page: 1, rows: 200 } }).then(response => {
      setDepartments(response.data.items);
    });
  }, []);

  function handleChange(index: keyof Patrimony, value: any) {
    setPatrimony(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!patrimony) return;

    e?.preventDefault();

    setValidation({});

    validate(patrimony)
      .then(handleSubmit)
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/patrimonies`, { ...patrimony, branch_id: patrimony.branch_id.toString() })
      .then(response => {
        handleOpen('Patrimônio cadastrado com sucesso');
        history.push('/patrimonies');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      <Appbar
        title="Novo patrimônio"
        ActionsComponent={
          <Mobile>
            <IconButton color="inherit" onClick={() => handleValidation()}>
              <Done />
            </IconButton>
          </Mobile>
        }
      />
      <PageHeaderActions
        backAction={() => history.push('/patrimonies')}
        title="Novo patrimônio"
        ActionComponent={<PatrimonyActions handleValidation={handleValidation} />}
      />
      {saving && <InsideSaving />}

      <PatrimonyForm
        departments={departments}
        patrimony={patrimony}
        handleChange={handleChange}
        handleValidation={handleValidation}
        validation={validation}
      />
    </>
  );
};

export default PatrimonyNew;
