import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { Department } from 'types/department';

const CustomListItemButton = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
});

type DepartmentItemModuleProps = {
  department: Department;
};

const DepartmentItemModule: React.FC<DepartmentItemModuleProps> = ({ department }) => {
  function handleClick() {
    history.push(`/departments/${department.id}`);
  }

  return (
    <CustomListItemButton onClick={handleClick}>
      <Grid>
        <Typography variant="caption">ID:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {department.id}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Nome:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {department.name}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Criado em:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {department.formattedCreatedAt}
        </Typography>
      </Grid>
    </CustomListItemButton>
  );
};

export default DepartmentItemModule;
