import { Button, styled, Typography } from '@mui/material';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import React from 'react';

const Container = styled('div')({
  display: 'flex',
  height: 200,
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-around',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
});

type InvoiceConfirmDeleteProps = {
  onExited(): void;
  handleSubmit?(): void;
  titleId: number;
};

const InvoiceConfirmDelete: React.FC<InvoiceConfirmDeleteProps> = ({ handleSubmit, onExited, titleId }) => {
  function handleYesClick(handleCloseModal: () => void) {
    if (handleSubmit) handleSubmit();
    handleCloseModal();
  }
  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <Container>
            <Typography align="center">
              Deseja realmente excluir o título <strong>{titleId}</strong>?
            </Typography>
            <Actions>
              <Button color="primary" variant="text" onClick={context.handleClose}>
                Cancelar
              </Button>
              <Button color="primary" variant="contained" onClick={() => handleYesClick(context.handleClose)}>
                Sim, excluir
              </Button>
            </Actions>
          </Container>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default InvoiceConfirmDelete;
