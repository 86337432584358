import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

interface PatrimonyActionsProps {
  handleValidation(): void;
}

const PatrimonyActions: React.FC<PatrimonyActionsProps> = ({ handleValidation }) => {
  return (
    <Tooltip title="Salvar patrimônio">
      <IconButton onClick={handleValidation} color="inherit">
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default PatrimonyActions;
