import React from 'react';
import { AbcProductPayment } from 'types/abcProduct';
import { Typography, ListItemButton, styled } from '@mui/material';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& > div': {
    gap: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&.data': {
      gap: 5,
    },
    '& > span': {
      borderRadius: 6,
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        backgroundColor: '#ddd',
      },
      '& > p': {
        padding: 5,
      },
    },
  },
});

type PaymentItemModuleProps = {
  payment: AbcProductPayment;
};

const PaymentItemModule: React.FC<PaymentItemModuleProps> = ({ payment }) => {
  return (
    <CustomListItemButton>
      <Typography variant="subtitle2" color="primary">
        {payment.forma_pagamento}
      </Typography>
      <div>
        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>Pedido por:</b>
          </Typography>
          <Typography color="#30519f" align="right">
            {payment.pedido_por}
          </Typography>
        </span>

        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>Valor:</b>
          </Typography>
          <Typography color="green" align="right">
            {payment.formattedValue}
          </Typography>
        </span>
      </div>
    </CustomListItemButton>
  );
};

export default PaymentItemModule;
