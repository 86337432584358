import { Button } from '@mui/material';
import React from 'react';
import history from 'services/history';

const PatrimonyAction: React.FC = () => {
  function handleRedirect() {
    history.push('/patrimonies/new');
  }
  return (
    <Button onClick={handleRedirect} variant="contained" color="primary">
      Adicionar
    </Button>
  );
};

export default PatrimonyAction;
