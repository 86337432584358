import React, { useState, useEffect, useCallback } from 'react';
import AbcProductPage, { AbcProductFilterDates, AbcProductFilterParams } from './AbcProduct';
import ProductProvider from './hooks/useAbcProducts';
import { endOfDay, format, startOfDay, subDays, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';

const CurveAbc: React.FC = () => {
  const user = useSelector(state => state.user);
  const initialDate = startOfDay(subDays(new Date(), 13));
  const finalDate = endOfDay(subDays(new Date(), 7));
  const branches = useSelector(state => state.branches);
  const initialDateToCompare = startOfDay(addDays(finalDate, 1));
  const finalDateToCompare = endOfDay(new Date());

  const formatDate = useCallback((date: Date | null) => {
    if (!date) {
      return '';
    }

    return format(date, 'P', { locale: ptBR });
  }, []);

  const [filter, setFilter] = useState<AbcProductFilterParams>({
    dates: {
      initialDate,
      finalDate,
      initialDateToCompare,
      finalDateToCompare,
      formattedInitialDate: format(initialDate, 'P', { locale: ptBR }),
      formattedFinalDate: format(finalDate, 'P', { locale: ptBR }),
      formattedInitialDateToCompare: format(initialDateToCompare, 'P', { locale: ptBR }),
      formattedFinalDateToCompare: format(finalDateToCompare, 'P', { locale: ptBR }),
    },
    selectedBranchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    productId: 0,
    typeSale: 'T',
    categoryIds: [],
    subCategoryIds: [],
    madeBy: '',
    segmentIds: [],
    unitIds: [],
    subGroupIds: [],
    manufacturerIds: [],
  });

  useEffect(() => {
    setFilter(state => ({
      ...state,
      dates: {
        ...state.dates,
        formattedInitialDate: formatDate(filter.dates.initialDate),
      },
    }));
  }, [filter.dates.initialDate, formatDate]);

  useEffect(() => {
    setFilter(state => ({
      ...state,
      dates: {
        ...state.dates,
        formattedFinalDate: formatDate(filter.dates.finalDate),
      },
    }));
  }, [filter.dates.finalDate, formatDate]);

  useEffect(() => {
    setFilter(state => ({
      ...state,
      dates: {
        ...state.dates,
        formattedInitialDateToCompare: formatDate(filter.dates.initialDateToCompare),
      },
    }));
  }, [filter.dates.initialDateToCompare, formatDate]);

  useEffect(() => {
    setFilter(state => ({
      ...state,
      dates: {
        ...state.dates,
        formattedFinalDateToCompare: formatDate(filter.dates.finalDateToCompare),
      },
    }));
  }, [filter.dates.finalDateToCompare, formatDate]);

  function handleChangeFilter(index: keyof AbcProductFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleDatesFilterChange(index: keyof AbcProductFilterDates, value: any) {
    setFilter(state => ({
      ...state,
      dates: {
        ...state.dates,
        [index]: value,
      },
    }));
  }

  return (
    <ProductProvider filter={filter}>
      <AbcProductPage
        handleDatesFilterChange={handleDatesFilterChange}
        handleChangeFilter={handleChangeFilter}
        filter={filter}
      />
    </ProductProvider>
  );
};

export default CurveAbc;
