import React, { useState, useEffect, useRef } from 'react';
import { Button, styled, TextField } from '@mui/material';
import Dialog, { useDialog } from 'components/dialogs/Dialog';
import { useResellerRejectionValidation } from './useResellerRejectionValidation';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
});

interface ResellerRejectionProps {
  handleReject(reason: string, handleCloseDialog: () => void): void;
  onExited(): void;
}

const ResellerRejection: React.FC<ResellerRejectionProps> = ({ handleReject, onExited }) => {
  const [reason, setReason] = useState('');
  const dialog = useDialog();
  const [validation, setValidation, validade] = useResellerRejectionValidation();
  const inputs = {
    email: useRef<HTMLInputElement>(null),
    reason: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(inputs) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleValidation(handleCloseDialog: () => void) {
    setValidation({});

    validade({ reason })
      .then(() => handleConfirm(handleCloseDialog))
      .catch(err => console.error(err));
  }

  function handleConfirm(handleCloseDialog: () => void) {
    handleReject(reason, dialog.handleClose);
    handleCloseDialog();
  }

  return (
    <Dialog maxWidth="sm" height={400} onExited={onExited}>
      <Container>
        <TextField
          label="Informe o motivo"
          fullWidth
          margin="normal"
          value={reason}
          onChange={e => setReason(e.target.value)}
          rows={5}
          multiline
          inputRef={inputs.reason}
          error={!!validation.reason}
          helperText={validation.reason}
        />
        <Actions>
          <Button variant="text" color="primary" onClick={onExited}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleValidation(onExited)}>
            Confirmar
          </Button>
        </Actions>
      </Container>
    </Dialog>
  );
};

export default ResellerRejection;
