import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { Button, TextField, styled } from '@mui/material';
import { ProjectProductFormContent } from 'types/project';
import { ProjectProductValidation } from './validation/projectProductValidation';
import { Product } from 'types/product';
import ProductsAutoComplete from './auto-complete/ProductsAutoComplete';
import { useApp } from 'hooks/app';

const Form = styled('form')(props => ({
  padding: '15px 0',
  display: 'grid',
  maxWidth: 900,
  gridTemplateColumns: '100px 1fr 120px 140px 80px',
  flex: 0,
  gap: 10,
  alignItems: 'center',
  marginBottom: 30,
  '& > button': {
    flexShrink: 0,
  },
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    alignItems: 'end',
  },
}));

interface ProjectProductFormProps {
  handleSubmit(data: ProjectProductFormContent): Promise<void>;
  validation: ProjectProductValidation;
}

const ProjectProductForm: React.FC<ProjectProductFormProps> = ({ handleSubmit: defaultHandleSubmit, validation }) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [quantity, setQuantity] = useState('');
  const [product, setProduct] = useState<Product | null>(null);
  const { h2iApi } = useApp();

  const inputs = {
    id: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    quantity: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    if (!product) {
      setName('');
      setValue(``);
      setId('');
      return;
    }

    setName(product.produto);
    setValue(`${product.p2br}`);
    setId(`${product.codigo_produto}`);
  }, [product]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    defaultHandleSubmit({
      id,
      name,
      value,
      quantity,
    })
      .then(() => {
        setId('');
        setValue('');
        setName('');
        setQuantity('');
        setProduct(null);
        inputs.id.current?.focus();
      })
      .catch(err => console.error(err));
  }

  function handleSelectProduct(product: Product | null) {
    setProduct(product);
    inputs.quantity.current?.focus();
  }

  async function handleSearchProduct(): Promise<void> {
    if (!h2iApi) {
      return;
    }

    if (!id) {
      return;
    }

    const response = await h2iApi.get('/api/produtoTodos', { params: { id } });
    const product = response.data[0];

    setProduct(product);
    setName(product.descricao);
    setValue(product.p1);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        label="Código"
        placeholder="Código"
        autoFocus
        value={id}
        onChange={event => setId(event.target.value)}
        inputRef={inputs.id}
        error={!!validation.id}
        helperText={validation.id}
        onBlur={handleSearchProduct}
        fullWidth
      />

      <ProductsAutoComplete
        label="Pesquisa de produtos"
        onSelect={product => handleSelectProduct(product)}
        placeholder="Digite o nome do produto"
        selected={product}
        helperText={validation.name}
        error={!!validation.name}
      />

      <TextField
        label="Valor (R$)"
        placeholder="Valor do produto"
        value={value}
        onChange={event => setValue(event.target.value)}
        inputRef={inputs.value}
        error={!!validation.value}
        helperText={validation.value}
        type="number"
        inputMode="decimal"
        fullWidth
      />

      <TextField
        label="Quantidade"
        placeholder="Quantidade do produto no projeto"
        value={quantity}
        onChange={event => setQuantity(event.target.value)}
        inputRef={inputs.quantity}
        error={!!validation.quantity}
        helperText={validation.quantity}
        type="number"
        fullWidth
        inputMode="numeric"
      />

      <Button type="submit" size="small" variant="contained" color="primary">
        inserir
      </Button>
    </Form>
  );
};

export default ProjectProductForm;
