import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import { useSelector } from 'store/selector';
import ProductListTable from './list/product/table/ProductList';
import ProductListModule from './list/product/module/ProductList';
import StockBalanceReportData from './StockBalanceReportData';
import StockBalanceFilter from '../StockBalanceFilter';
import ResponsibleListTable from './list/responsible/table/ResponsibleListTable';
import ResponsibleListModule from './list/responsible/module/ResponsibleListModule';
import { StockBalanceProvider } from './list/hooks/useStockBalance';
import SubgroupListTable from './list/subgroup/table/SubgroupListTable';
import SubgroupListModule from './list/subgroup/module/SubgroupListModule';
import BranchListModule from './list/branch/module/BranchListModule';
import BranchListTable from './list/branch/table/BranchListTable';
import StockMoreFilter from '../StockMoreFilter';
import UnityListTable from './list/unity/table/UnityListTable';
import UnityListModule from './list/unity/module/UnityListModule';
import SegmentListTable from './list/segment/table/SegmentListTable';
import SegmentListModule from './list/segment/module/SegmentListModule';
import Appbar from 'components/appbar/Appbar';
import StockBalanceTotal from '../StockBalanceTotal';
import StockBalanceExport from '../StockBalanceExport';
import ManufacturerListTable from './list/manufacturer/table/ManufacturerListTable';
import StockBalanceTabs from './tab/StockBalanceTabs';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchStockBalance } from './hooks/useFetchStockBalance';

const Container = styled('div')({
  zoom: '90%',
  display: 'flex',
  flexDirection: 'column',
});

const StockBalance: React.FC = () => {
  const { handleOpenMenu, isOpenedMenu } = useApp();
  const [tab, setTab] = useState('branch');
  const branches = useSelector(state => state.branches);
  const [excelExport, setExcelExport] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [displayMode] = useDisplayMode();
  const [shownReport, setShownReport] = useState(false);
  const {
    handleSearch,
    handleChange,
    handleSortManufacturer,
    handleSortResponsible,
    handleSortBranch,
    handleSortStoppedStock,
    handleSortSubgroup,
    handleSortUnity,
    handleSortSegment,
    handleSortItem,
    filter,
    loadingResponsibles,
    loadingSubgroups,
    loadingUnits,
    loadingSegments,
    loadingItems,
    loadingManufacturers,
    loadingStoppedStock,
    loadingBranches,
    filteredResponsibles,
    filteredSubgroups,
    filteredUnits,
    filteredSegments,
    filteredItems,
    filteredBranches,
    selectedResponsible,
    setSelectedResponsible,
    moreResponsible,
    setMoreResponsible,
    moreBranch,
    setMoreBranch,
    moreUnity,
    setMoreUnity,
    moreSegment,
    setMoreSegment,
    moreStoppedStock,
    setMoreStoppedStock,
    moreManufacturer,
    setMoreManufacturer,
    filteredStoppedStocks,
    filteredManufacturers,
    formattedTotal,
    orderedIndex,
  } = useFetchStockBalance();

  const selectedBranches = useMemo(() => {
    const branch = branches.filter(branch => filter.branchId.some(item => item === branch.id));
    return branch;
  }, [branches, filter.branchId]);

  return (
    <StockBalanceProvider
      value={{
        moreSegment,
        setMoreSegment,
        moreUnity,
        setMoreUnity,
        moreBranch,
        setMoreBranch,
        moreStoppedStock,
        setMoreStoppedStock,
        moreResponsible,
        setMoreResponsible,
        moreManufacturer,
        setMoreManufacturer,
        setSelectedResponsible,
        selectedResponsible,
        filter,
      }}
    >
      {moreFilter && (
        <StockMoreFilter
          filter={filter}
          onSearch={() => handleSearch(filter)}
          onExited={() => setMoreFilter(false)}
          handleChangeFilter={handleChange}
        />
      )}

      {shownReport && (
        <StockBalanceReportData
          data={filteredItems}
          branches={selectedBranches}
          onExited={() => setShownReport(false)}
        />
      )}

      <Appbar title="Saldo de Estoque" />

      <StockBalanceFilter
        filter={filter}
        setExcelExport={setExcelExport}
        openMoreFilter={() => setMoreFilter(true)}
        openReport={() => setShownReport(true)}
        displayMode={displayMode}
        handleChange={handleChange}
        onSearch={filter => handleSearch(filter, isOpenedMenu, handleOpenMenu)}
      />

      <StockBalanceTabs tab={tab} onChangeTab={tab => setTab(tab)} />

      <StockBalanceTotal loading={loadingItems} formattedTotal={formattedTotal} />

      {excelExport && <StockBalanceExport filter={filter} onExited={() => setExcelExport(false)} />}

      {tab === 'responsible' ? (
        loadingResponsibles ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredResponsibles.length === 0 ? (
          <NoData message="Nenhum responsável encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ResponsibleListTable
                  isSubgroup
                  responsibles={filteredResponsibles}
                  handleSort={handleSortResponsible}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <ResponsibleListModule responsibles={filteredResponsibles} />
              )}
              <Pagination count={filteredResponsibles.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'branch' ? (
        loadingBranches ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredBranches.length === 0 ? (
          <NoData message="Nenhuma filial encontrada." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <BranchListTable
                  withoutMovement={false}
                  stoppedStock={false}
                  isSubgroup
                  branches={filteredBranches}
                  handleSort={handleSortBranch}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <BranchListModule branches={filteredBranches} />
              )}
              <Pagination count={filteredBranches.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'unity' ? (
        loadingUnits ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredUnits.length === 0 ? (
          <NoData message="Nenhuma unidade encontrada." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <UnityListTable units={filteredUnits} handleSort={handleSortUnity} orderedIndex={orderedIndex} />
              ) : (
                <UnityListModule units={filteredUnits} />
              )}
              <Pagination count={filteredUnits.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'segment' ? (
        loadingSegments ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredSegments.length === 0 ? (
          <NoData message="Nenhum segmento encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <SegmentListTable
                  segments={filteredSegments}
                  handleSort={handleSortSegment}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SegmentListModule segments={filteredSegments} />
              )}
              <Pagination count={filteredSegments.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'subgroup' ? (
        loadingSubgroups ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredSubgroups.length === 0 ? (
          <NoData message="Nenhum subgrupo encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <SubgroupListTable
                  subgroups={filteredSubgroups}
                  handleSort={handleSortSubgroup}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SubgroupListModule subgroups={filteredSubgroups} />
              )}
              <Pagination count={filteredSubgroups.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'items' ? (
        loadingItems ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredItems.length === 0 ? (
          <NoData message="Nenhum item encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ProductListTable
                  isSubgroup={false}
                  products={filteredItems}
                  handleSort={handleSortItem}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <ProductListModule products={filteredItems} />
              )}
              <Pagination count={filteredItems.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'manufacturer' ? (
        loadingManufacturers ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredManufacturers.length === 0 ? (
          <NoData message="Nenhum fabricante encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ManufacturerListTable
                  manufacturers={filteredManufacturers}
                  handleSort={handleSortManufacturer}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <>{/* <ManufacturerListModule products={filteredManufacturers} /> */}</>
              )}
              <Pagination count={filteredManufacturers.length} />
            </Container>
          </PaginationProvider>
        )
      ) : loadingStoppedStock ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredStoppedStocks.length === 0 ? (
        <NoData message="Nenhum estoque sem movimentação encontrado." />
      ) : (
        <PaginationProvider>
          <Container>
            {displayMode === 'list' ? (
              <BranchListTable
                withoutMovement={true}
                stoppedStock
                isSubgroup
                branches={filteredStoppedStocks}
                handleSort={handleSortStoppedStock}
                orderedIndex={orderedIndex}
              />
            ) : (
              <BranchListModule branches={filteredStoppedStocks} />
            )}
            <Pagination count={filteredStoppedStocks.length} />
          </Container>
        </PaginationProvider>
      )}
    </StockBalanceProvider>
  );
};

export default StockBalance;
