import React, { useState } from 'react';
import CustomerProvider from './hooks/useAbcCustomers';
import AbcCustomerPage from './AbcCustomer';
import { FilterMore } from 'components/filter-more/FilterMore';
import { endOfDay, startOfDay } from 'date-fns';
import { useSelector } from 'store/selector';

export interface AbcCustomersFilterParams extends FilterMore {
  initialDate: Date | null;
  finalDate: Date | null;
  selectedBranchId: number;
  productId: number;
  typeSale: string;
}

const CurveAbc: React.FC = () => {
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [filter, setFilter] = useState<AbcCustomersFilterParams>({
    initialDate: startOfDay(new Date()),
    finalDate: endOfDay(new Date()),
    selectedBranchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    productId: 0,
    typeSale: 'T',
    categoryIds: [],
    subCategoryIds: [],
    madeBy: '',
    segmentIds: [],
    unitIds: [],
    subGroupIds: [],
    manufacturerIds: [],
  });

  function handleChangeFilter(index: keyof AbcCustomersFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }
  return (
    <CustomerProvider filter={filter}>
      <AbcCustomerPage filter={filter} handleChangeFilter={handleChangeFilter} />
    </CustomerProvider>
  );
};

export default CurveAbc;
