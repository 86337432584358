import React, { useCallback, useEffect, useState } from 'react';
import { ActivityMethod, CityMethod, PaymentMethod, SalesMethod } from 'types/salesReport';
import { styled } from '@mui/material';
import { Sales, SalesItem } from 'types/reports/sales';
import CityListTable from './list/city/table/CityListTable';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import ModalityListTable from './list/modality/table/ModalityListTable';
import ActivityListTable from './list/activity/table/ActivityListTable';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import GroupedSaleListTable, { GroupedSale } from './list/order-by/list/table/GroupedSaleListTable';
import NoData from 'components/no-data/NoData';
import SalesReportDashboardTabs from './SalesReportDashboardTabs';
import InsideLoading from 'components/loading/InsideLoading';
import { ArcElement, Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ActivityChart from './list/activity/ActivityChart';
import CityChart from './list/city/CityChart';
import ModalityChart from './list/modality/ModalityChart';
import { useDisplayMode } from 'hooks/useDisplayMode';
import SalesListModule from './list/items/module/SalesListModule';
import SalesListTable from './list/items/table/SalesListTable';
import Pagination from 'components/pagination/Pagination';
import GroupedSegmentListTable, { GroupedSegment } from './list/segment/table/GroupedSegmentListTable';
import SegmentChart from './list/segment/SegmentChart';
import GroupedUnityListTable, { GroupedUnity } from './list/unity/table/GroupedUnityListTable';
import UnityChart from './list/unity/UnityChart';
import PaymentListTable from './list/payment/table/PaymentListTable';
import PaymentChart from './list/payment/PaymentChart';
import GroupedSaleListModule from './list/order-by/list/module/GroupedSaleListModule';
import ActivityListModule from './list/activity/module/ActivityListModule';
import CityListModule from './list/city/module/CityListModule';
import ModalityListModule from './list/modality/module/ModalityListModule';
import PaymentMethodListModule from './list/payment-methods/module/PaymentMethodListModule';
import GroupedUnityListModule from './list/unity/module/GroupedUnityListModule';
import GroupedSegmentListModule from './list/segment/module/GroupedSegmentListModule';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

Chart.register(ArcElement, ChartDataLabels);

const Container = styled('div')({
  gap: 15,
  display: 'flex',
  flexDirection: 'column',
});

const ContentColor = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  maxWidth: '100%',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

interface SalesReportDashboardProps {
  salesMethods: SalesMethod[];
  activitiesMethods: ActivityMethod[];
  citiesMethods: CityMethod[];
  paymentsMethods: PaymentMethod[];
  sales: Sales[];
  salesItems: SalesItem[];
  loading: boolean;
}

const SalesReportDashboard: React.FC<SalesReportDashboardProps> = ({
  activitiesMethods,
  citiesMethods,
  salesMethods,
  paymentsMethods,
  sales,
  salesItems,
  loading,
}) => {
  const [displayMode] = useDisplayMode();
  const [filteredSalesItems, setFilteredSalesItems] = useState<SalesItem[]>([]);
  const [filteredSales, setFilteredSales] = useState<GroupedSale[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<GroupedSegment[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<GroupedUnity[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<ActivityMethod[]>([]);
  const [filteredCities, setFilteredCities] = useState<CityMethod[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<PaymentMethod[]>([]);
  const [filteredModalities, setFilteredModalities] = useState<SalesMethod[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [tab, setTab] = useState('order_by');

  const groupSalesByPedidoPor = useCallback((): GroupedSale[] => {
    const groupedSales: { [key: string]: GroupedSale } = {};

    sales.forEach(sale => {
      if (!groupedSales[sale.pedido_por]) {
        groupedSales[sale.pedido_por] = {
          pedido_por: sale.pedido_por,
          vl_total: 0,
          spiff: 0,
          mc: 0,
          qtd: 0,
          ticket_medio: 0,
          percentual: 0,
          formattedMc: '',
          formattedSpiff: '',
          formattedTicketMedio: '',
          formattedPercentual: '',
          formattedTotal: '',
          vendas: [],
        };
      }

      groupedSales[sale.pedido_por].qtd += 1;
      groupedSales[sale.pedido_por].vl_total += sale.vl_total;
      groupedSales[sale.pedido_por].spiff += sale.spiff;
      groupedSales[sale.pedido_por].mc += sale.mc;
      groupedSales[sale.pedido_por].percentual =
        (groupedSales[sale.pedido_por].vl_total / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100;
      groupedSales[sale.pedido_por].vendas.push(sale);
    });

    return Object.values(groupedSales);
  }, [sales, salesMethods]);

  const groupUnits = useCallback((): GroupedUnity[] => {
    const groupedUnits: { [key: string]: GroupedUnity } = {};

    salesItems.forEach(sale => {
      if (!groupedUnits[sale.unidade]) {
        groupedUnits[sale.unidade] = {
          unidade: sale.unidade,
          total: 0,
          spiff: 0,
          mc: 0,
          qtd: 0,
          custo: 0,
          percentual: 0,
          qtd_vendas: 0,
          formattedCusto: '',
          formattedMc: '',
          formattedSpiff: '',
          formattedPercentual: '',
          formattedTotal: '',
          vendas: [],
        };
      }
      groupedUnits[sale.unidade].qtd += sale.qtd;
      groupedUnits[sale.unidade].qtd_vendas += sale.qtd_vendas;
      groupedUnits[sale.unidade].custo += sale.custo;
      groupedUnits[sale.unidade].total += sale.total;
      groupedUnits[sale.unidade].spiff += sale.spiff;
      groupedUnits[sale.unidade].mc += sale.mc;
      groupedUnits[sale.unidade].formattedCusto = moneyFormat(groupedUnits[sale.unidade].custo);
      groupedUnits[sale.unidade].formattedTotal = moneyFormat(groupedUnits[sale.unidade].total);
      groupedUnits[sale.unidade].formattedMc = moneyFormat(groupedUnits[sale.unidade].mc);
      groupedUnits[sale.unidade].formattedSpiff = moneyFormat(groupedUnits[sale.unidade].spiff);
      groupedUnits[sale.unidade].percentual =
        (groupedUnits[sale.unidade].total / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100;
      groupedUnits[sale.unidade].formattedPercentual = percentFormat(groupedUnits[sale.unidade].percentual);
      groupedUnits[sale.unidade].vendas.push(sale);
    });

    return Object.values(groupedUnits);
  }, [salesItems, salesMethods]);

  const groupSegments = useCallback((): GroupedSegment[] => {
    const groupedSegments: { [key: string]: GroupedSegment } = {};

    salesItems.forEach(sale => {
      if (!groupedSegments[sale.segmento]) {
        groupedSegments[sale.segmento] = {
          segmento: sale.segmento,
          total: 0,
          spiff: 0,
          mc: 0,
          qtd: 0,
          custo: 0,
          percentual: 0,
          qtd_vendas: 0,
          formattedCusto: '',
          formattedMc: '',
          formattedSpiff: '',
          formattedPercentual: '',
          formattedTotal: '',
          vendas: [],
        };
      }
      groupedSegments[sale.segmento].qtd += sale.qtd;
      groupedSegments[sale.segmento].qtd_vendas += sale.qtd_vendas;
      groupedSegments[sale.segmento].custo += sale.custo;
      groupedSegments[sale.segmento].total += sale.total;
      groupedSegments[sale.segmento].spiff += sale.spiff;
      groupedSegments[sale.segmento].mc += sale.mc;
      groupedSegments[sale.segmento].formattedCusto = moneyFormat(groupedSegments[sale.segmento].custo);
      groupedSegments[sale.segmento].formattedTotal = moneyFormat(groupedSegments[sale.segmento].total);
      groupedSegments[sale.segmento].formattedMc = moneyFormat(groupedSegments[sale.segmento].mc);
      groupedSegments[sale.segmento].formattedSpiff = moneyFormat(groupedSegments[sale.segmento].spiff);
      groupedSegments[sale.segmento].percentual =
        (groupedSegments[sale.segmento].total / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100;
      groupedSegments[sale.segmento].formattedPercentual = percentFormat(groupedSegments[sale.segmento].percentual);
      groupedSegments[sale.segmento].vendas.push(sale);
    });

    return Object.values(groupedSegments);
  }, [salesItems, salesMethods]);

  function handleSortPayment(index: string) {
    const p = sort(index, filteredPayments);
    setFilteredPayments(p);
  }

  function handleSortCity(index: string) {
    const p = sort(index, filteredCities);
    setFilteredCities(p);
  }

  function handleSortModality(index: string) {
    const p = sort(index, filteredModalities);
    setFilteredModalities(p);
  }

  function handleSortActivity(index: string) {
    const p = sort(index, filteredActivities);
    setFilteredActivities(p);
  }

  function handleSortGroupedSale(index: string) {
    const p = sort(index, filteredSales);
    setFilteredSales(p);
  }

  function handleSortGroupedUnity(index: string) {
    const p = sort(index, filteredUnits);
    setFilteredUnits(p);
  }

  function handleSortGroupedSegment(index: string) {
    const p = sort(index, filteredSegments);
    setFilteredSegments(p);
  }

  function handleSortItems(index: string) {
    const filtered = sort(index, filteredSalesItems);
    setFilteredSalesItems(filtered);
  }

  useEffect(() => {
    setFilteredCities(
      citiesMethods.map(item => ({
        ...item,
        ticket_medio: item.valor / item.qtd_vendas,
        formattedTicketMedio: numberFormat(item.valor / item.qtd_vendas),
        percentual: (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        formattedPercentual: percentFormat(
          (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        ),
      })),
    );
  }, [citiesMethods, salesMethods]);

  useEffect(() => {
    setFilteredPayments(
      paymentsMethods.map(item => ({
        ...item,
        formattedDate: format(item.data, 'P', { locale: ptBR }),
        ticket_medio: item.valor / item.qtd_vendas,
        formattedTicketMedio: numberFormat(item.valor / item.qtd_vendas),
        percentual: (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        formattedPercentual: percentFormat(
          (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        ),
      })),
    );
  }, [paymentsMethods, salesMethods]);

  useEffect(() => {
    setFilteredModalities(
      salesMethods.map(item => ({
        ...item,
        ticket_medio: item.valor / item.qtd_vendas,
        formattedTicketMedio: numberFormat(item.valor / item.qtd_vendas),
        percentual: (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        formattedPercentual: percentFormat(
          (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        ),
      })),
    );
  }, [salesMethods]);

  useEffect(() => {
    setFilteredActivities(
      activitiesMethods.map(item => ({
        ...item,
        ticket_medio: item.valor / item.qtd_vendas,
        formattedTicketMedio: numberFormat(item.valor / item.qtd_vendas),
        percentual: (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        formattedPercentual: percentFormat(
          (item.valor / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
        ),
      })),
    );
  }, [activitiesMethods, salesMethods]);

  useEffect(() => {
    setFilteredSales(
      groupSalesByPedidoPor().map(item => ({
        ...item,
        ticket_medio: item.vl_total / item.qtd,
        formattedMc: moneyFormat(item.mc),
        formattedPercentual: percentFormat(item.percentual),
        formattedSpiff: moneyFormat(item.spiff),
        formattedTicketMedio: numberFormat(item.vl_total / item.qtd),
        formattedTotal: moneyFormat(item.vl_total),
      })),
    );
  }, [groupSalesByPedidoPor]);

  useEffect(() => {
    setFilteredSegments(groupSegments());
  }, [groupSegments]);

  useEffect(() => {
    setFilteredUnits(groupUnits());
  }, [groupUnits]);

  useEffect(() => {
    setFilteredSalesItems(salesItems);
  }, [salesItems]);

  return (
    <>
      <SalesReportDashboardTabs
        activities={filteredActivities}
        cities={filteredCities}
        segments={filteredSegments}
        units={filteredUnits}
        sales={filteredSales}
        payments={filteredPayments}
        items={filteredSalesItems}
        disabled={loading}
        onChange={e => setTab(e)}
        tab={tab}
      />

      {loading ? (
        <InsideLoading />
      ) : sales.length > 0 ? (
        <Container>
          {tab === 'order_by' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <GroupedSaleListTable
                      orderedIndex={orderedIndex}
                      handleSort={handleSortGroupedSale}
                      groupedSales={filteredSales}
                    />
                  ) : (
                    <GroupedSaleListModule groupedSales={filteredSales} />
                  )}

                  <Pagination count={filteredSales.length} />
                </Container>
              </PaginationProvider>
            </ContentColor>
          ) : tab === 'activities' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <ActivityListTable
                      orderedIndex={orderedIndex}
                      handleSort={handleSortActivity}
                      activities={filteredActivities}
                    />
                  ) : (
                    <ActivityListModule activities={filteredActivities} />
                  )}

                  <Pagination count={filteredActivities.length} />
                </Container>
              </PaginationProvider>

              <ActivityChart salesMethods={salesMethods} methods={activitiesMethods} />
            </ContentColor>
          ) : tab === 'cities' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <CityListTable orderedIndex={orderedIndex} handleSort={handleSortCity} cities={filteredCities} />
                  ) : (
                    <CityListModule cities={filteredCities} />
                  )}

                  <Pagination count={filteredCities.length} />
                </Container>
              </PaginationProvider>

              <CityChart salesMethods={salesMethods} methods={citiesMethods} />
            </ContentColor>
          ) : tab === 'modalities' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <ModalityListTable
                      orderedIndex={orderedIndex}
                      handleSort={handleSortModality}
                      modalities={filteredModalities}
                    />
                  ) : (
                    <ModalityListModule modalities={filteredModalities} />
                  )}

                  <Pagination count={filteredModalities.length} />
                </Container>
              </PaginationProvider>

              <ModalityChart salesMethods={salesMethods} />
            </ContentColor>
          ) : tab === 'payment_methods' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <PaymentListTable
                      orderedIndex={orderedIndex}
                      handleSort={handleSortPayment}
                      payments={filteredPayments}
                    />
                  ) : (
                    <PaymentMethodListModule payments={filteredPayments} />
                  )}

                  <Pagination count={filteredPayments.length} />
                </Container>
              </PaginationProvider>

              <PaymentChart salesMethods={salesMethods} methods={filteredPayments} />
            </ContentColor>
          ) : tab === 'items' ? (
            <PaginationProvider>
              <Container>
                {displayMode === 'list' ? (
                  <SalesListTable sales={filteredSalesItems} handleSort={handleSortItems} orderedIndex={orderedIndex} />
                ) : (
                  <SalesListModule sales={filteredSalesItems} />
                )}
                <Pagination count={filteredSalesItems.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'segments' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <GroupedSegmentListTable
                      orderedIndex={orderedIndex}
                      handleSort={handleSortGroupedSegment}
                      groupedSegments={filteredSegments}
                    />
                  ) : (
                    <GroupedSegmentListModule groupedSegments={filteredSegments} />
                  )}

                  <Pagination count={filteredSegments.length} />
                </Container>
              </PaginationProvider>

              <SegmentChart segments={filteredSegments} salesMethods={salesMethods} />
            </ContentColor>
          ) : tab === 'units' ? (
            <ContentColor>
              <PaginationProvider>
                <Container>
                  {displayMode === 'list' ? (
                    <GroupedUnityListTable
                      orderedIndex={orderedIndex}
                      handleSort={handleSortGroupedUnity}
                      groupedUnits={filteredUnits}
                    />
                  ) : (
                    <GroupedUnityListModule groupedUnits={filteredUnits} />
                  )}

                  <Pagination count={filteredUnits.length} />
                </Container>
              </PaginationProvider>

              <UnityChart units={filteredUnits} salesMethods={salesMethods} />
            </ContentColor>
          ) : (
            <ContentColor>
              <PaginationProvider>
                <GroupedUnityListTable
                  orderedIndex={orderedIndex}
                  handleSort={handleSortGroupedUnity}
                  groupedUnits={filteredUnits}
                />
              </PaginationProvider>

              <UnityChart units={filteredUnits} salesMethods={salesMethods} />
            </ContentColor>
          )}
        </Container>
      ) : (
        <NoData message="Sem vendas encontradas nesse período" />
      )}
    </>
  );
};

export default SalesReportDashboard;
