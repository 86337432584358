import React, { ReactNode, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import AbcProductTotal from './AbcProductTotal';
import PaginationProvider from 'hooks/pagination';
import { useFetchViews } from './hooks/useFetchViews';
import { styled } from '@mui/material';
import { useAbcProducts } from './hooks/useAbcProducts';
import AbcProductFilterBox from './AbcProductFilterBox';
import Pagination from 'components/pagination/Pagination';
import AbcProductDateFilter from './AbcProductDateFilter';
import SelectedBranch from './modal/branch/SelectedBranch';
import { AbcProductProvider } from './hooks/useAbcProduct';
import UnityListTable from './unity/list/table/UnityListTable';
import UnityListModule from './unity/list/module/UnityListModule';
import BranchListTable from './branch/list/table/BranchListTable';
import AbcProductListTable from './list/table/AbcProductListTable';
import SegmentListTable from './segment/list/table/SegmentListTable';
import BranchListModule from './branch/list/module/BranchListModule';
import AbcProductListModule from './list/module/AbcProductListModule';
import SubgroupListTable from './subgroup/list/table/SubgroupListTable';
import CategoryListTable from './category/list/table/CategoryListTable';
import SegmentListModule from './segment/list/module/SegmentListModule';
import CategoryListModule from './category/list/module/CategoryListModule';
import SubgroupListModule from './subgroup/list/module/SubgroupListModule';
import ManufacturerListTable from './manufacturer/list/table/ManufacturerListTable';
import ManufacturerListModule from './manufacturer/list/module/ManufacturerListModule';
import SellerListTable from './seller/list/table/SellerListTable';
import SellerListModule from './seller/list/module/SellerListModule';
import SelectedSale from 'components/sale/SelectedSale';
import SelectedSegment from './segment/SelectedSegment';
import CustomerListTable from './customer/list/table/CustomerListTable';
import CustomerListModule from './customer/list/module/CustomerListModule';
import FilterMorePage, { FilterMore } from 'components/filter-more/FilterMore';
import AbcProductExport from './export/AbcProductExport';
import { segmentTableTemplate } from './segment/segmentTableTemplate';
import AbcProductTabs from './AbcProductTabs';
import InsideLoading from 'components/loading/InsideLoading';
import AbcProductActions from './actions/AbcProductActions';
import PaymentListTable from './payment/list/table/PaymentListTable';
import PaymentListModule from './payment/list/module/PaymentListModule';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export interface AbcProductFilterParams extends FilterMore {
  initialDate: Date | null;
  finalDate: Date | null;
  selectedBranchId: number;
  productId: number;
  typeSale: string;
  customer: string;
}

interface AbcProductPageProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
}

const AbcProductPage: React.FC<AbcProductPageProps> = ({ filter, handleChangeFilter }) => {
  const [tab, setTab] = useState('category');
  const {
    setSelectedSale,
    selectedSale,
    filteredSegments,
    handleSortSegment,
    filteredProducts,
    handleSort,
    filteredUnits,
    handleSortUnity,
    filteredBranches,
    handleSortBranch,
    filteredCategories,
    handleSortCategory,
    filteredSubgroups,
    handleSortSubgroup,
    filteredManufacturers,
    handleSortManufacturer,
    filteredSellers,
    handleSortSeller,
    filteredPayments,
    handleSortPayment,
    filteredCustomers,
    handleSortCustomer,
    displayMode,
    searchText,
    handleSearch,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    formattedTotal,
    orderedIndex,
  } = useAbcProducts();

  const [
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDate,
    excelExport,
    openModalBranch,
    openModalSegment,
    dialogDateDesktop,
    setExcelExport,
    setOpenModalBranch,
    setOpenModalSegment,
    setDialogDate,
    setDialogDateDesktop,
  ] = useFetchViews(handleSearch, searchText);

  function handleTabsRendering(): ReactNode {
    const tabContents: Record<string, ReactNode> = {
      category: (
        <PaginationProvider>
          <Container>
            {filteredCategories.length === 0 ? (
              <NoData message="Nenhuma categoria encontrada." />
            ) : displayMode === 'list' ? (
              <CategoryListTable
                categories={filteredCategories}
                handleSort={handleSortCategory}
                orderedIndex={orderedIndex}
              />
            ) : (
              <CategoryListModule categories={filteredCategories} />
            )}
            <Pagination count={filteredCategories.length} />
          </Container>
        </PaginationProvider>
      ),
      branch: (
        <PaginationProvider>
          <Container>
            {filteredBranches.length === 0 ? (
              <NoData message="Nenhuma filial encontrada." />
            ) : displayMode === 'list' ? (
              <BranchListTable
                isSubgroup
                branches={filteredBranches}
                handleSort={handleSortBranch}
                orderedIndex={orderedIndex}
              />
            ) : (
              <BranchListModule branches={filteredBranches} />
            )}
            <Pagination count={filteredBranches.length} />
          </Container>
        </PaginationProvider>
      ),
      subgroup: (
        <PaginationProvider>
          <div>
            {filteredSubgroups.length === 0 ? (
              <NoData message="Nenhum subgrupo encontrado." />
            ) : displayMode === 'list' ? (
              <SubgroupListTable
                isSubgroup
                subgroups={filteredSubgroups}
                handleSort={handleSortSubgroup}
                orderedIndex={orderedIndex}
              />
            ) : (
              <SubgroupListModule subgroups={filteredSubgroups} />
            )}
            <Pagination count={filteredSubgroups.length} />
          </div>
        </PaginationProvider>
      ),
      unity: (
        <PaginationProvider>
          <Container>
            {filteredUnits.length === 0 ? (
              <NoData message="Nenhuma unidade encontrada." />
            ) : displayMode === 'list' ? (
              <UnityListTable
                isSubgroup
                units={filteredUnits}
                handleSort={handleSortUnity}
                orderedIndex={orderedIndex}
              />
            ) : (
              <UnityListModule units={filteredUnits} />
            )}
            <Pagination count={filteredUnits.length} />
          </Container>
        </PaginationProvider>
      ),
      segment: (
        <PaginationProvider>
          <Container>
            {filteredSegments.length === 0 ? (
              <NoData message="Nenhum segmento encontrado." />
            ) : displayMode === 'list' ? (
              <SegmentListTable
                isSubgroup
                tableTemplate={segmentTableTemplate}
                segments={filteredSegments}
                handleSort={handleSortSegment}
                orderedIndex={orderedIndex}
              />
            ) : (
              <SegmentListModule segments={filteredSegments} />
            )}
            <Pagination count={filteredSegments.length} />
          </Container>
        </PaginationProvider>
      ),
      customer: (
        <PaginationProvider>
          <div>
            {filteredCustomers.length === 0 ? (
              <NoData message="Nenhum vendedor encontrado." />
            ) : displayMode === 'list' ? (
              <CustomerListTable
                customers={filteredCustomers}
                handleSort={handleSortCustomer}
                orderedIndex={orderedIndex}
              />
            ) : (
              <CustomerListModule customers={filteredCustomers} />
            )}
            <Pagination count={filteredCustomers.length} />
          </div>
        </PaginationProvider>
      ),
      manufacturer: (
        <PaginationProvider>
          <div>
            {filteredManufacturers.length === 0 ? (
              <NoData message="Nenhum fabricante encontrado." />
            ) : displayMode === 'list' ? (
              <ManufacturerListTable
                isSubgroup
                manufacturers={filteredManufacturers}
                handleSort={handleSortManufacturer}
                orderedIndex={orderedIndex}
              />
            ) : (
              <ManufacturerListModule manufacturers={filteredManufacturers} />
            )}
            <Pagination count={filteredManufacturers.length} />
          </div>
        </PaginationProvider>
      ),
      order_by: (
        <PaginationProvider>
          <div>
            {filteredSellers.length === 0 ? (
              <NoData message="Nenhum vendedor encontrado." />
            ) : displayMode === 'list' ? (
              <SellerListTable
                isSubgroup
                sellers={filteredSellers}
                handleSort={handleSortSeller}
                orderedIndex={orderedIndex}
              />
            ) : (
              <SellerListModule sellers={filteredSellers} />
            )}
            <Pagination count={filteredSellers.length} />
          </div>
        </PaginationProvider>
      ),
      product: (
        <PaginationProvider>
          <Container>
            {displayMode === 'list' ? (
              <AbcProductListTable
                isSubgroup
                products={filteredProducts}
                handleSort={handleSort}
                orderedIndex={orderedIndex}
              />
            ) : (
              <AbcProductListModule products={filteredProducts} />
            )}
            <Pagination count={filteredProducts.length} />
          </Container>
        </PaginationProvider>
      ),
      payment_method: (
        <PaginationProvider>
          <Container>
            {displayMode === 'list' ? (
              <PaymentListTable
                payments={filteredPayments}
                handleSort={handleSortPayment}
                orderedIndex={orderedIndex}
              />
            ) : (
              <PaymentListModule payments={filteredPayments} />
            )}
            <Pagination count={filteredPayments.length} />
          </Container>
        </PaginationProvider>
      ),
    };

    return tabContents[tab];
  }

  return (
    <AbcProductProvider
      value={{ filter, handleChangeFilter, searchValue: searchText, setOpenModalBranch, setOpenModalSegment }}
    >
      {selectedSale && <SelectedSale onExited={() => setSelectedSale(null)} selectedSale={selectedSale} />}
      {openModalBranch && <SelectedBranch onExited={() => setOpenModalBranch(false)} />}
      {openModalSegment && <SelectedSegment onExited={() => setOpenModalSegment(false)} />}

      {dialogDate && (
        <AbcProductDateFilter
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
        />
      )}

      {dialogDateDesktop && (
        <FilterMorePage
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          onExited={() => setDialogDateDesktop(false)}
          handleChangeFilter={handleChangeFilter}
        />
      )}

      {excelExport && (
        <AbcProductExport
          products={filteredProducts}
          categories={filteredCategories}
          branches={filteredBranches}
          segments={filteredSegments}
          subgroups={filteredSubgroups}
          units={filteredUnits}
          customers={filteredCustomers}
          sellers={filteredSellers}
          manufacturers={filteredManufacturers}
          onExited={() => setExcelExport(false)}
        />
      )}

      <Appbar
        title="Curva Abc de Produtos"
        ActionsComponent={
          <AbcProductActions
            disabled={loading}
            handleOpenDialog={() => setDialogDate(true)}
            handleOpenExcel={() => setExcelExport(true)}
          />
        }
      />

      <AbcProductFilterBox
        filter={filter}
        disabled={filteredProducts.length === 0}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      <AbcProductTabs tab={tab} onChange={tab => setTab(tab)} />

      <AbcProductTotal formattedTotal={formattedTotal} />

      {loading ? (
        <InsideLoading />
      ) : filteredProducts.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <>
          {handleTabsRendering()}

          {/* <CategoryChart formattedTotalChart={filteredUnits} /> */}
        </>
      )}
    </AbcProductProvider>
  );
};

export default AbcProductPage;
