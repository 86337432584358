import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Theme, useTheme } from '@mui/material';
import { useBranches } from 'providers/BranchesProviders';
import { Branch } from 'types/branch';

interface SelectMultiOthersBranchesProps {
  branchIds: number[];
  availableBranches?: number[];
  label?: string;
  size?: 'small' | 'medium';
  handleChange(value: number[]): void;
  loading?: boolean;
  setBranch?(branch: Branch): void;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

function getStyles(name: number, personName: readonly number[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    backgroundColor: personName.indexOf(name) === -1 ? '#fff' : '#cfffe6',
  };
}

const SelectMultiOthersBranches: React.FC<SelectMultiOthersBranchesProps> = ({
  branchIds,
  handleChange,
  loading = false,
  setBranch,
  label = 'Filiais',
  size = 'medium',
  availableBranches = [],
  style = {
    minWidth: 150,
    height: '100%',
  },
  labelStyle,
}) => {
  const theme = useTheme();
  const { financialBranches } = useBranches();

  return (
    <FormControl>
      <InputLabel style={labelStyle}>{label}</InputLabel>
      <Select
        size={size}
        style={style}
        value={branchIds}
        disabled={loading}
        onChange={e => handleChange(e.target.value as number[])}
        multiple
        label={label}
      >
        {financialBranches
          .filter(item => (availableBranches.length > 0 ? availableBranches.includes(item.id) : item))
          .map(branch => (
            <MenuItem
              style={getStyles(branch.id, branchIds, theme)}
              onClick={() => (setBranch ? setBranch(branch) : undefined)}
              key={branch.id}
              value={branch.id}
            >
              {branch.nome}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectMultiOthersBranches;
