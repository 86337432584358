import { moneyFormat } from 'helpers/numberFormat';
import { Reserve } from 'types/reserve';

export interface AgroupedBranchReserve {
  filial: string;
  qtd: number;
  valor_total: number;
  formattedTotalValue: string;
  itens: Reserve[];
}

export interface AgroupedSellerReserve {
  vendedor: string;
  qtd: number;
  valor_total: number;
  formattedTotalValue: string;
}

export interface AgroupedProductReserve {
  id_produto: number;
  produto: string;
  qtd: number;
  valor_total: number;
  formattedTotalValue: string;
}

export function useFetchReserveDashboard(items: Reserve[]) {
  function groupByProduct(data: Reserve[]): AgroupedProductReserve[] {
    const productMap = new Map();

    data.forEach(_item => {
      if (!productMap.has(_item.id_produto)) {
        productMap.set(_item.id_produto, {
          id_produto: _item.id_produto,
          produto: _item.produto,
          valor_total: 0,
          qtd: 0,
          formattedTotalValue: 'R$ 0,00',
        });
      }
      const item = productMap.get(_item.id_produto);
      item.valor_total += _item.valor_total;
      item.qtd += _item.qtd;
      item.formattedTotalValue = moneyFormat(item.valor_total);
    });

    return Array.from(productMap.values());
  }

  function groupBySeller(data: Reserve[]): AgroupedSellerReserve[] {
    const buyerMap = new Map();

    data.forEach(_item => {
      if (!buyerMap.has(_item.vendedor)) {
        buyerMap.set(_item.vendedor, {
          vendedor: _item.vendedor,
          valor_total: 0,
          qtd: 0,
          formattedTotalValue: 'R$ 0,00',
        });
      }
      const item = buyerMap.get(_item.vendedor);
      item.valor_total += _item.valor_total;
      item.qtd += _item.qtd;
      item.formattedTotalValue = moneyFormat(item.valor_total);
    });

    return Array.from(buyerMap.values());
  }

  const groupedProducts = groupByProduct(items).sort((a, b) => b.valor_total - a.valor_total);
  const groupedSellers = groupBySeller(items).sort((a, b) => b.valor_total - a.valor_total);

  return {
    groupedSellers,
    groupedProducts,
  };
}
