import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import { useSelector } from 'store/selector';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { Button } from '@mui/material';
import { useApp } from 'hooks/app';
import { Segment } from 'types/segment';
import { ExportEmailProvider } from './hooks/useExportEmail';
import ExportEmailFilterBox from './ExportEmailFilterBox';
import { format, parseISO, subDays } from 'date-fns';
import { Email } from 'types/email';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import TableContainer from 'components/table/TableContainer';
import { exportEmailTableTemplate } from './exportEmailTableTemplate';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';
import ExportEmailListModule from './list/module/ExportEmailListModule';
import ExportEmailListTable from './list/table/ExportEmailListTable';
import { ptBR } from 'date-fns/locale';
import ExportEmailActions from './ExportEmailActions';
import ExportEmailSearch from './ExportEmailSearch';
import { usePermissionRules } from 'hooks/permissionRules';
import EmailExport from './excel/EmailExport';
import { makeStyles } from '@mui/styles';
import { useDisplayMode } from 'hooks/useDisplayMode';

export interface QueryParams {
  data_ini: Date;
  data_fim: Date;
  tipo: 'N' | 'S' | 'V';
  id_filial: number;
  id_atividade: number | string;
}

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const ExportEmail: React.FC = () => {
  const classes = styles();
  const user = useSelector(state => state.user);
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [emails, setEmails] = useState<Email[]>([]);
  const { h2iApi } = useApp();
  const [openDialog, setOpenDialog] = useState(false);
  const [filtered, setFiltered] = useState<Email[]>([]);
  const branches = useSelector(state => state.branches);
  const [excelExport, setExcelExport] = useState(false);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [displayMode] = useDisplayMode();
  const { checkPermission } = usePermissionRules();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    data_ini: subDays(new Date(), 30),
    data_fim: new Date(),
    id_filial:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    id_atividade: '',
    tipo: 'N',
  });

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoadingActivity(true);

    h2iApi
      .get('/api/getatividade')
      .then(response => {
        const _segments: Segment[] = response.data.atividade;
        setSegments(_segments);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingActivity(false));
  }, [h2iApi]);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get('/api/exportemail', {
        params: {
          ...queryParams,
          id_filial: queryParams.id_filial || '',
          data_ini: format(parseISO(queryParams.data_ini.toISOString()), 'P', { locale: ptBR }),
          data_fim: format(parseISO(queryParams.data_fim.toISOString()), 'P', { locale: ptBR }),
        },
      })
      .then(response => setEmails(response.data))
      .catch(err => {
        setEmails([]);
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, queryParams]);

  useEffect(() => {
    setFiltered(emails);
  }, [emails]);

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <ExportEmailProvider value={{ branches, segments, filtered }}>
      {excelExport && <EmailExport onExited={() => setExcelExport(false)} data={filtered} />}

      {openDialog && (
        <ExportEmailSearch
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
          onExited={() => setOpenDialog(false)}
        />
      )}
      <div className={classes.container}>
        <Appbar
          title="Exportar e-mail"
          ActionsComponent={
            <ExportEmailActions setExcelExport={setExcelExport} openDialog={() => setOpenDialog(true)} />
          }
        />
        <PageHeaderActions
          title="Exportar e-mail"
          ActionComponent={
            <>
              {checkPermission('general.excel_export_button') && (
                <Button
                  disabled={filtered.length === 0}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setExcelExport(true)}
                >
                  Exportar excel
                </Button>
              )}
            </>
          }
        />

        <ExportEmailFilterBox handleQueryParamsChange={handleQueryParamsChange} queryParams={queryParams} />

        {loading || loadingActivity ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : emails.length === 0 ? (
          <NoData message="Nenhum email para mostrar" />
        ) : (
          <TableContainer tableTemplate={exportEmailTableTemplate}>
            <PaginationProvider>
              <div className={classes.container}>
                {displayMode === 'list' ? (
                  <ExportEmailListTable emails={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
                ) : (
                  <ExportEmailListModule emails={filtered} />
                )}
                <Pagination count={filtered.length} />
              </div>
            </PaginationProvider>
          </TableContainer>
        )}
      </div>
    </ExportEmailProvider>
  );
};

export default ExportEmail;
