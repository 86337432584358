import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Chip, TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import UsersAutoCompleteAdornment from './UsersAutoCompleteAdornment';
import styles from './autocomplete.module.css';
import { User } from 'types/user';
import { api } from 'services/api';
import UserAutoCompleteItem from './UserAutoCompleteItem';

let timer: NodeJS.Timeout;

interface AutocompleteFromProps {
  onSelect(user: User | null): void;
  label: string;
  placeholder: string;
  selectedUsers: User[];
  helperText: string;
  error: boolean;
  handleDeleteUser(index: number): void;
}

const UsersAutoComplete: React.FC<AutocompleteFromProps> = ({
  onSelect,
  label,
  placeholder,
  selectedUsers,
  helperText,
  error,
  handleDeleteUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!error) {
      return;
    }

    inputRef.current?.focus();
  }, [error]);

  const handleSelect = useCallback(
    (user: User) => {
      onSelect(user);
      setFiltered([]);
    },
    [onSelect],
  );

  useEffect(() => {
    setFiltered(users);
  }, [users]);

  const findUsers = useCallback(async (term: string) => {
    setLoading(true);
    api
      .get(`/users`, { params: { term, active: 1 } })
      .then(response => {
        setUsers(response.data);
      })
      .finally(() => setLoading(false))
      .catch(err => console.error(err));
  }, []);

  const clearSelected = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  function handleChange(value: string) {
    clearTimeout(timer);

    if (value.length < 3) {
      return;
    }

    timer = setTimeout(() => findUsers(value), 400);
  }

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      id="reseller-autocomplete"
      open={filtered.length > 0}
      options={filtered}
      value={selectedUsers}
      fullWidth
      filterOptions={users => users}
      getOptionLabel={user => user.name}
      multiple
      filterSelectedOptions
      renderOption={(props, option) => (
        <UserAutoCompleteItem handleSelect={handleSelect} selectedUsers={selectedUsers} user={option} {...props} />
      )}
      renderTags={(value: User[], getTagProps) =>
        value.map((option: User, index: number) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={option.name}
            key={option.id}
            onDelete={() => handleDeleteUser(index)}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          multiline
          rows={2}
          inputRef={inputRef}
          label={label}
          placeholder={placeholder}
          onChange={event => handleChange(event.target.value)}
          onBlur={() => setFiltered([])}
          margin="normal"
          error={error}
          helperText={helperText}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <UsersAutoCompleteAdornment
                loading={loading}
                selectedUsers={selectedUsers}
                clearSelected={clearSelected}
              />
            ),
          }}
        />
      )}
    />
  );
};

export default UsersAutoComplete;
