import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

export type ReserveAnalysisTabOptions = 'items' | 'dashboard';

type ReserveAnalysisTabProps = {
  handleChange(value: ReserveAnalysisTabOptions): void;
  tab: ReserveAnalysisTabOptions;
};

const ReserveAnalysisTab: React.FC<ReserveAnalysisTabProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="dashboard" />
        <Tab label="Relação de reserva" value="items" />
        <Tab label="Por filial" value="branches" />
        <Tab label="Por produto" value="products" />
      </Tabs>
    </Container>
  );
};

export default ReserveAnalysisTab;
