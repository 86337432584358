import React, { FormEvent, useEffect, useState } from 'react';
import InsideSaving from 'components/loading/InsideSaving';
import history from 'services/history';
import { api } from 'services/api';
import { Department } from 'types/department';
import { useMessaging } from 'hooks/messaging';
import { useDepartmentValidation } from '../validation/departmentValidation';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import DepartmentForm from '../DepartmentForm';
import Appbar from 'components/appbar/Appbar';
import DepartmentActions from '../DepartmentActions';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { IconButton, styled } from '@mui/material';
import { Done } from '@mui/icons-material';

const Mobile = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const DepartmentUpdate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const initialState = {
    id: id ?? '',
    name: '',
  };
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useDepartmentValidation();
  const [department, setDepartment] = useState<Department>(initialState);
  const { handleOpen } = useMessaging();

  useEffect(() => {
    setLoading(true);

    api
      .get(`/hr-departments/${id}`)
      .then(response => {
        setDepartment(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof Department, value: any) {
    setDepartment(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!department) return;

    e?.preventDefault();

    setValidation({});

    validate(department)
      .then(handleSubmit)
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/hr-departments/${department.id}`, department)
      .then(response => {
        handleOpen('Departamento cadastrado com sucesso');
        history.push('/departments');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      <Appbar
        title="Editar departamento"
        ActionsComponent={
          <Mobile>
            <IconButton color="inherit" onClick={() => handleValidation()}>
              <Done />
            </IconButton>
          </Mobile>
        }
      />
      <PageHeaderActions
        backAction={() => history.push('/departments')}
        title="Editar departamento"
        ActionComponent={<DepartmentActions handleValidation={handleValidation} />}
      />
      {saving && <InsideSaving />}
      {loading ? (
        <InsideLoading />
      ) : (
        <DepartmentForm
          department={department}
          handleChange={handleChange}
          handleValidation={handleValidation}
          validation={validation}
        />
      )}
    </>
  );
};

export default DepartmentUpdate;
