import React, { MouseEvent, useState } from 'react';
import { AbcProductBranch } from 'types/abcProduct';
import { IconButton, styled, Typography } from '@mui/material';
import { useAbcCustomers } from '../../../hooks/useAbcCustomers';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import { download } from 'helpers/download';
import { api } from 'services/api';
import { uuidv4 } from 'helpers/uuidv4';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import { branchTableTemplate } from '../../branchTableTemplate';
import { useAbcCustomer } from '../../../hooks/useAbcCustomer';
import InsideSaving from 'components/loading/InsideSaving';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

type BranchItemTableProps = {
  branch: AbcProductBranch;
  isSubgroup: boolean;
};

const BranchItemTable: React.FC<BranchItemTableProps> = ({ branch, isSubgroup }) => {
  const { h2iApi } = useApp();
  const { setMoreBranch, moreBranch } = useAbcCustomers();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useAbcCustomer();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreBranch(moreBranch ? null : branch);
  }

  async function handleExportExcel(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    setLoading(true);
    const response = await h2iApi?.get(`/api/relcurvaabccliente`, {
      params: {
        data_ini: formattedInitialDate,
        data_fim: formattedFinalDate,
        id_filial: branch.id_filial,
        id_subgrupo: filter.subGroupIds.join(',') || '',
        cliente: searchValue,
        tipo_venda: filter.typeSale,
        id_categoria: filter.categoryIds.join(','),
        id_subcategoria: filter.subCategoryIds.join(','),
        pedido_por: filter.madeBy,
        id_segmento: filter.segmentIds.join(','),
        id_unidade: filter.unitIds.join(','),
        id_fabricante: filter.manufacturerIds.join(','),
      },
    });

    if (!response || response.data[0].result) {
      return;
    }

    try {
      const responseExcel = await api.post('/excel-exports', response.data, { responseType: 'blob' });

      download(responseExcel.data, `sem-movimentacao-${branch.filial}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <InsideSaving />}
      {branchTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : item.id === 'more' && isSubgroup ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreBranch?.filial === branch.filial ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : item.id === 'excel' ? (
            <CustomIconButton color="primary" key={item.id} onClick={handleExportExcel}>
              <FaFileExcel />
            </CustomIconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {branch[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BranchItemTable;
