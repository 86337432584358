import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  MonthlyBranch,
  MonthlyPeriod,
  MonthlyCategory,
  MonthlyManufacturer,
  CategoryMonthly,
} from 'types/monthlyComparison';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { ProductGroup } from 'types/product';
import useTableOrder from 'hooks/tableOrder';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import MonthlyFilterBox from './MonthlyFilterBox';
import { moneyFormat } from 'helpers/numberFormat';
import { Theme } from '@mui/material';
import MonthlyFilterMobile from './MonthlyFilterMobile';
import Pagination from 'components/pagination/Pagination';
import ReportLoading from 'components/loading/ReportLoading';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import MonthlyComparisonActions from './MonthlyComparisonActions';
import PeriodListTable from './period/list/table/PeriodListTable';
import BranchListTable from './branch/list/table/BranchListTable';
import PeriodListModule from './period/list/module/PeriodListModule';
import BranchListModule from './branch/list/module/BranchListModule';
import CategoryListTable from './category/list/table/CategoryListTable';
import { MonthlyComparisonProvider } from './hooks/useMonthlyComparison';
import CategoryListModule from './category/list/module/CategoryListModule';
import ManufacturerListTable from './manufacturer/list/table/ManufacturerListTable';
import ManufacturerListModule from './manufacturer/list/module/ManufacturerListModule';
import { SegmentFilter, UnityFilter } from 'components/filter-more/hooks/useFetchMoreFilters';
import MonthlyComparisonExport from './export/MonthlyComparisonExport';
import { makeStyles } from '@mui/styles';
import { useDisplayMode } from 'hooks/useDisplayMode';
import MonthlyComparisonTabs from './tab/MonthlyComparisonTabs';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '150px 200px 1fr',
    columnGap: 10,
    flex: 1,
    '& > .search-params': {
      marginLeft: 5,
      display: 'grid',
      gridTemplateColumns: '200px 150px 200px',
      columnGap: 10,
    },
  },
  select: {
    marginTop: '16px',
  },
  iconButton: {
    marginTop: '10px',
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '-50px',
  },
  searchParams: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    verticalAlign: 'middle',
    marginTop: '150px',
  },
}));

export interface MonthlyFilterParams {
  selectedBranchId: number;
  productId: number;
  typeSale: string;
  finalDate: Date;
  initialDate: Date;
  segment: number;
  unity: number;
  category: number;
}

const MonthlyComparison: React.FC = () => {
  const classes = useStyles();
  const { isOpenedMenu, handleOpenMenu, h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const [tab, setTab] = useState('dashboard');
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dialogDate, setDialogDate] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const allBranches = useSelector(state => state.branches);
  const [groups, setGroups] = useState<ProductGroup[]>([]);
  const [segments, setSegments] = useState<SegmentFilter[]>([]);
  const [units, setUnits] = useState<UnityFilter[]>([]);
  const [periods, setPeriods] = useState<MonthlyPeriod[]>([]);
  const [branches, setBranches] = useState<MonthlyBranch[]>([]);
  const [categories, setCategories] = useState<MonthlyCategory[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<CategoryMonthly[]>([]);
  const [manufacturers, setManufacturers] = useState<MonthlyManufacturer[]>([]);
  const [moreBranch, setMoreBranch] = useState<MonthlyBranch | null>(null);
  const [morePeriod, setMorePeriod] = useState<MonthlyPeriod | null>(null);
  const [filteredPeriods, setFilteredPeriods] = useState<MonthlyPeriod[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<MonthlyBranch[]>([]);
  const [moreCategory, setMoreCategory] = useState<MonthlyCategory | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<MonthlyBranch | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<MonthlyPeriod | null>(null);
  const [filteredCategories, setFilteredCategories] = useState<MonthlyCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<MonthlyCategory | null>(null);
  const [moreManufacturer, setMoreManufacturer] = useState<MonthlyManufacturer | null>(null);
  const [filteredManufacturers, setFilteredManufacturers] = useState<MonthlyManufacturer[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<MonthlyManufacturer | null>(null);
  const [displayMode] = useDisplayMode();
  const [filter, setFilter] = useState<MonthlyFilterParams>({
    selectedBranchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    productId: 0,
    typeSale: 'T',
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
    segment: 0,
    unity: 0,
    category: 0,
  });

  useEffect(() => {
    setFilteredPeriods(periods);
  }, [periods]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  useEffect(() => {
    setFilteredManufacturers(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    setFilteredBranches(allBranches);
  }, [allBranches]);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    h2iApi.get('/api/grupo').then(response => {
      setGroups(response.data);
    });

    h2iApi
      .get('/api/produtocategorias')
      .then(response => setCategoriesFilter(response.data))
      .catch(err => console.error(err));

    h2iApi.get('/api/produto_segmentos').then(response => {
      setSegments(response.data);
    });

    h2iApi.get('/api/produto_unidades').then(response => {
      setUnits(response.data);
    });
  }, [h2iApi]);

  const handleSearch = useCallback(
    async (searchValue: string) => {
      if (!h2iApi) return;
      setDialogDate(false);

      if (isOpenedMenu) {
        handleOpenMenu();
      }

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      await h2iApi
        .get(`/api/relcomparativomensal`, {
          params: {
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.productId || '',
            filtro: searchValue,
            tipo_venda: filter.typeSale,
            id_segmento: filter.segment || '',
            id_unidade: filter.unity || '',
            id_categoria: filter.category || '',
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
          },
        })
        .then(response => {
          const _response = response.data[0];
          const categorias: MonthlyCategory[] = _response.categoria;
          const filiais: MonthlyBranch[] = _response.filial;
          const periodos: MonthlyPeriod[] = _response.periodo;

          setCategories(categorias);

          setBranches(filiais);

          const _periods = periodos.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);

            return item;
          });

          setPeriods(_periods);
        })
        .catch(err => console.error(err));

      await h2iApi
        .get('/api/relcomparativofabricante', {
          params: {
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.productId || '',
            filtro: searchValue,
            tipo_venda: filter.typeSale,
            id_segmento: filter.segment || '',
            id_unidade: filter.unity || '',
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
          },
        })
        .then(response => {
          const _manufacturers: MonthlyManufacturer[] = response.data.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);

            return item;
          });

          setManufacturers(_manufacturers);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi, filter, isOpenedMenu, handleOpenMenu],
  );

  function handleChangeFilter(index: keyof MonthlyFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string, _sort: 'category' | 'period' | 'manufacturer' | 'branch') {
    switch (_sort) {
      case 'branch':
        setFilteredBranches(sort(index, filteredBranches));
        return;
      case 'category':
        setFilteredCategories(sort(index, filteredCategories));
        return;
      case 'manufacturer':
        setFilteredManufacturers(sort(index, filteredManufacturers));
        return;
      case 'period':
        setFilteredPeriods(sort(index, filteredPeriods));
    }
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch(searchText);
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <MonthlyComparisonProvider
      value={{
        searchValue: searchText,
        moreCategory,
        setMoreCategory,
        selectedCategory,
        setSelectedCategory,
        morePeriod,
        setMorePeriod,
        selectedPeriod,
        setSelectedPeriod,
        moreBranch,
        setMoreBranch,
        selectedBranch,
        setSelectedBranch,
        moreManufacturer,
        setMoreManufacturer,
        selectedManufacturer,
        setSelectedManufacturer,
        filter,
        handleChangeFilter,
      }}
    >
      {dialogDate && (
        <MonthlyFilterMobile
          filter={filter}
          onSearch={() => handleSearch(searchText)}
          handleChangeFilter={handleChangeFilter}
          segments={segments}
          units={units}
          categories={categoriesFilter}
          groups={groups}
          onExited={() => setDialogDate(false)}
        />
      )}

      <Appbar
        title="Comparativo mensal"
        ActionsComponent={
          <>
            <MonthlyComparisonActions
              handleOpenExcelExport={() => setExcelExport(true)}
              handleOpenDialog={() => setDialogDate(true)}
            />
          </>
        }
      />

      <MonthlyFilterBox
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleSearchSubmit={handleSearchSubmit}
      />

      <MonthlyComparisonTabs tab={tab} handleChange={value => setTab(value)} />

      {excelExport && (
        <MonthlyComparisonExport
          periods={periods}
          onExited={() => setExcelExport(false)}
          branches={branches}
          categories={categories}
          manufacturers={manufacturers}
        />
      )}

      {loading ? (
        <ReportLoading displayMode={displayMode} />
      ) : filteredPeriods.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : tab === 'dashboard' ? (
        <PaginationProvider>
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <PeriodListTable
                isSubgroup
                periods={filteredPeriods}
                handleSort={handleSort}
                orderedIndex={orderedIndex}
              />
            ) : (
              <PeriodListModule periods={filteredPeriods} />
            )}
            <Pagination count={filteredPeriods.length} />
          </div>
        </PaginationProvider>
      ) : tab === 'branch' ? (
        <PaginationProvider>
          <div className={classes.container}>
            {filteredCategories ? (
              <>
                {displayMode === 'list' ? (
                  <BranchListTable
                    isSubgroup
                    branches={filteredBranches}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <BranchListModule branches={filteredBranches} />
                )}
                <Pagination count={filteredBranches.length} />
              </>
            ) : (
              <NoData message="Não possui filiais para mostrar" />
            )}
          </div>
        </PaginationProvider>
      ) : tab === 'category' ? (
        <PaginationProvider>
          <div className={classes.container}>
            {filteredCategories ? (
              <>
                {displayMode === 'list' ? (
                  <CategoryListTable
                    isSubgroup
                    categories={filteredCategories}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <CategoryListModule categories={filteredCategories} />
                )}
                <Pagination count={filteredCategories.length} />
              </>
            ) : (
              <NoData message="Não possui categorias para mostrar" />
            )}
          </div>
        </PaginationProvider>
      ) : (
        <PaginationProvider>
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <ManufacturerListTable
                isSubgroup
                handleSort={handleSort}
                manufacturers={filteredManufacturers}
                orderedIndex={orderedIndex}
              />
            ) : (
              <ManufacturerListModule manufacturers={filteredManufacturers} />
            )}
            <Pagination count={filteredManufacturers.length} />
          </div>
        </PaginationProvider>
      )}
    </MonthlyComparisonProvider>
  );
};

export default MonthlyComparison;
