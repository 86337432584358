import React, { useCallback, useState } from 'react';
import useSearch from 'hooks/search';
import ManufacturerItem from './ManufacturerItem';
import { TextField } from '@mui/material';
import styles from './autocomplete.module.css';
import { Autocomplete } from '@mui/lab';
import ManufacturerAutoCompleteAdornment from './ManufacturerAutoCompleteAdornment';
import { Manufacturer } from 'types/manufacturer';
import { useProduct } from '../Product';

let timer: NodeJS.Timeout;

const ManufacturerAutoComplete: React.FC = () => {
  const search = useSearch();
  const { manufacturers, onSelect, selectedManufacturer: manufacturer, loading } = useProduct();
  const [filtered, setFiltered] = useState<Manufacturer[]>([]);

  const handleSelectManufacturer = useCallback(
    (manufacturer: Manufacturer) => {
      onSelect(manufacturer);
      setFiltered([]);
    },
    [onSelect],
  );

  const findManufacturers = useCallback(
    async (term: string) => {
      const f = search(term, 'nome', manufacturers);
      setFiltered(f);
    },
    [manufacturers, search],
  );

  const clearSelected = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  function handleChange(value: string) {
    clearTimeout(timer);

    if (value.length < 2) {
      return;
    }

    timer = setTimeout(() => findManufacturers(value), 400);
  }

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      id="manufacturer-autocomplete"
      open={filtered.length > 0}
      options={filtered}
      value={manufacturer}
      fullWidth
      filterOptions={manufacturers => manufacturers}
      getOptionLabel={manufacturer => `${manufacturer.nome} - ${manufacturer.id_fabricante}`}
      renderOption={(props, option) => (
        <ManufacturerItem
          handleSelectManufacturer={handleSelectManufacturer}
          value={manufacturer}
          manufacturer={option}
          {...props}
        />
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Fabricante"
          placeholder="Selecione o fabricante"
          onChange={event => handleChange(event.target.value)}
          onBlur={() => setFiltered([])}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <ManufacturerAutoCompleteAdornment
                loading={loading}
                selected={manufacturer}
                clearSelected={clearSelected}
              />
            ),
          }}
        />
      )}
    />
  );
};

export default ManufacturerAutoComplete;
