import { IconButton, InputAdornment, styled, TextField } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import React, { FormEvent } from 'react';
import { MdSearch } from 'react-icons/md';
import { SaleActivityFilter } from './saleActivityReport';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import SelectBranch from 'components/select-branches/SelectBranch';

interface Props {
  setSelectedBranch(branchId: number): void;
  loading: boolean;
  filter: SaleActivityFilter;
  handleChangeFilter(index: keyof SaleActivityFilter, value: any): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
}

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '150px 300px 100px',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    marginLeft: 5,
    display: 'grid',
    gridTemplateColumns: '150px 150px',
    columnGap: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const SaleActivityReportFilterBox: React.FC<Props> = ({
  setSelectedBranch,
  handleSearchSubmit,
  filter,
  searchText,
  handleSearchInputChange,
  loading,
  handleChangeFilter,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.branchId}
          handleChange={e => handleChangeFilter('branchId', e)}
          loading={loading}
          setBranch={branch => setSelectedBranch(branch.id)}
        />

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
        <TextField
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <MdSearch color="#888" size={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Filter>
    </FilterBox>
  );
};

export default SaleActivityReportFilterBox;
