import React, { FormEvent, useEffect, useState } from 'react';
import InsideSaving from 'components/loading/InsideSaving';
import history from 'services/history';
import { api } from 'services/api';
import { Patrimony } from 'types/patrimony';
import { useMessaging } from 'hooks/messaging';
import { usePatrimonyValidation } from '../validation/patrimonyValidation';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import PatrimonyForm from '../PatrimonyForm';
import Appbar from 'components/appbar/Appbar';
import PatrimonyActions from '../PatrimonyActions';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { Department } from 'types/department';
import { IconButton, styled } from '@mui/material';
import { Done } from '@mui/icons-material';

const Mobile = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const PatrimonyUpdate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const initialState: Patrimony = {
    id: id ?? '',
    name: '',
    branch_id: '',
    department_id: '',
    responsible: '',
    is_active: 1,
    observation: '',
    serial_note_number: '',
    serial_number: '',
    department: [],
  };
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = usePatrimonyValidation();
  const [patrimony, setPatrimony] = useState<Patrimony>(initialState);
  const [departments, setDepartments] = useState<Department[]>([]);
  const { handleOpen } = useMessaging();

  useEffect(() => {
    api.get('/hr-departments', { params: { page: 1, rows: 200 } }).then(response => {
      setDepartments(response.data.items);
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/patrimonies/${id}`)
      .then(response => {
        setPatrimony(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof Patrimony, value: any) {
    setPatrimony(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!patrimony) return;

    e?.preventDefault();

    setValidation({});

    validate(patrimony)
      .then(handleSubmit)
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/patrimonies/${patrimony.id}`, { ...patrimony, branch_id: patrimony.branch_id.toString() })
      .then(response => {
        handleOpen('Patrimônio cadastrado com sucesso');
        history.push('/patrimonies');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      <Appbar
        title="Editar patrimônio"
        ActionsComponent={
          <Mobile>
            <IconButton color="inherit" onClick={() => handleValidation()}>
              <Done />
            </IconButton>
          </Mobile>
        }
      />
      <PageHeaderActions
        backAction={() => history.push('/patrimonies')}
        title="Editar patrimônio"
        ActionComponent={<PatrimonyActions handleValidation={handleValidation} />}
      />
      {saving && <InsideSaving />}
      {loading ? (
        <InsideLoading />
      ) : (
        <PatrimonyForm
          patrimony={patrimony}
          departments={departments}
          handleChange={handleChange}
          handleValidation={handleValidation}
          validation={validation}
        />
      )}
    </>
  );
};

export default PatrimonyUpdate;
