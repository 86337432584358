import Appbar from 'components/appbar/Appbar';
import React, { useEffect, useState } from 'react';
import SelloutTotal from './SelloutTotal';
import { SelloutProvider } from './hooks/useSellout';
import PageHeader from 'components/page-header/PageHeader';
import { useDisplayMode } from 'hooks/useDisplayMode';
import SelloutFilterBox from './SelloutFilterBox';
import { useSelector } from 'store/selector';

export interface SelloutFilterProps {
  branch_id: number;
  initialDate: Date;
  finalDate: Date;
  year: Date;
}

const filterInitialState = {
  initialDate: new Date(),
  finalDate: new Date(),
  branch_id: 999,
  year: new Date(),
};

const Sellout: React.FC = () => {
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [filter, setFilter] = useState<SelloutFilterProps>(filterInitialState);
  const [showTotals, setShowTotals] = useState(true);

  function handleChangeFilter(index: keyof SelloutFilterProps, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function fetch(initialDate: Date, finalDate: Date) {
    setLoading(false);
  }

  function handleSubmit(initialDate: Date, finalDate: Date) {
    fetch(initialDate, finalDate);
  }

  useEffect(() => {
    const branchId =
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999;

    setFilter(state => ({
      ...state,
      branch_id: branchId,
    }));
  }, [user, branches]);

  return (
    <SelloutProvider value={{ filter, handleChangeFilter, setShowTotals, showTotals, loading, handleSubmit }}>
      <Appbar title="Análise de Sell Out" />

      <PageHeader title="Análise de Sell Out" />

      <SelloutFilterBox displayMode={displayMode} setDisplayMode={setDisplayMode} />

      <SelloutTotal />
    </SelloutProvider>
  );
};

export default Sellout;
