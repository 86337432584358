import React from 'react';
import { Typography, styled } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import history from 'services/history';
import { Department } from 'types/department';

type DepartmentItemTableProps = {
  department: Department;
};

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: '100%',
});

const DepartmentItemTable: React.FC<DepartmentItemTableProps> = ({ department }) => {
  const { tableTemplate } = useTable();

  function onClick() {
    history.push(`/departments/${department.id}`);
  }

  return (
    <>
      {tableTemplate.map(item => (
        <NumericData onClick={onClick} key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
          <Description fontSize={12} variant="body2">
            {department[item.id]}
          </Description>
        </NumericData>
      ))}
    </>
  );
};

export default DepartmentItemTable;
