import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import TableContainer from 'components/table/TableContainer';
import { Patrimony } from 'types/patrimony';
import { patrimonyTableTemplate } from 'pages/patrimonies/template/patrimonyTableTemplate';
import PatrimonyItemTable from './PatrimonyItemTable';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

interface PatrimonyListTableProps {
  patrimonies: Patrimony[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const PatrimonyListTable: React.FC<PatrimonyListTableProps> = ({ patrimonies, handleSort, orderedIndex }) => {
  return (
    <TableContainer tableTemplate={patrimonyTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {patrimonyTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={() => handleSort(item.originalId)}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>

              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {patrimonies.map((patrimony, index) => (
            <TableRow style={{ minHeight: 20 }} activeNthChild key={index}>
              <PatrimonyItemTable patrimony={patrimony} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default PatrimonyListTable;
