import React, { FormEvent, useState } from 'react';
import InsideSaving from 'components/loading/InsideSaving';
import Appbar from 'components/appbar/Appbar';
import DepartmentActions from '../DepartmentActions';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { Department } from 'types/department';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import { useDepartmentValidation } from '../validation/departmentValidation';
import DepartmentForm from '../DepartmentForm';
import { IconButton, styled } from '@mui/material';
import { Done } from '@mui/icons-material';

const Mobile = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const initialState: Department = {
  id: '',
  name: '',
};

const DepartmentNew: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useDepartmentValidation();
  const [department, setDepartment] = useState<Department>(initialState);
  const { handleOpen } = useMessaging();

  function handleChange(index: keyof Department, value: any) {
    setDepartment(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!department) return;

    e?.preventDefault();

    setValidation({});

    validate(department)
      .then(handleSubmit)
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/hr-departments`, department)
      .then(response => {
        handleOpen('Departamento cadastrado com sucesso');
        history.push('/departments');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      <Appbar
        title="Novo departamento"
        ActionsComponent={
          <Mobile>
            <IconButton color="inherit" onClick={() => handleValidation()}>
              <Done />
            </IconButton>
          </Mobile>
        }
      />
      <PageHeaderActions
        backAction={() => history.push('/departments')}
        title="Novo departamento"
        ActionComponent={<DepartmentActions handleValidation={handleValidation} />}
      />
      {saving && <InsideSaving />}

      <DepartmentForm
        department={department}
        handleChange={handleChange}
        handleValidation={handleValidation}
        validation={validation}
      />
    </>
  );
};

export default DepartmentNew;
