import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React from 'react';
import NoData from 'components/no-data/NoData';
import { useDisplayMode } from 'hooks/useDisplayMode';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import { IconButton, styled } from '@mui/material';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/pagination/ApiPagination';
import { useFetchPatrimonies } from './hook/useFetchPatrimonies';
import PatrimonyFilterBox from './PatrimonyFilterBox';
import PatrimonyAction from './action/PatrimonyAction';
import PatrimonyListTable from './list/table/PatrimonyListTable';
import PatrimonyListModule from './list/module/PatrimonyListModule';
import { Add } from '@mui/icons-material';
import history from 'services/history';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Mobile = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const Patrimony: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const {
    fetchPatrimonies,
    departments,
    filter,
    filteredPatrimonies,
    handleChangeFilter,
    total,
    handleSort,
    loading,
    orderedIndex,
    onChangePage,
    onChangeRow,
  } = useFetchPatrimonies();

  function handleRedirect() {
    history.push('/patrimonies/new');
  }

  return (
    <>
      <Appbar
        title="Controle"
        ActionsComponent={
          <Mobile>
            <IconButton color="inherit" onClick={handleRedirect}>
              <Add />
            </IconButton>
          </Mobile>
        }
      />
      <PageHeaderActions title="Controle - Patrimônio" ActionComponent={<PatrimonyAction />} />

      <PatrimonyFilterBox
        departments={departments}
        loading={loading}
        fetchPatrimonies={fetchPatrimonies}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
      />

      <PaginationProvider>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredPatrimonies.length === 0 ? (
          <NoData message="Nenhum patrimônio encontrado" />
        ) : (
          <Container>
            {displayMode === 'list' ? (
              <PatrimonyListTable
                patrimonies={filteredPatrimonies}
                handleSort={handleSort}
                orderedIndex={orderedIndex}
              />
            ) : (
              displayMode === 'module' && <PatrimonyListModule patrimonies={filteredPatrimonies} />
            )}
            <ApiPagination
              onChangePage={value => onChangePage(value)}
              onChangeRowsPerPage={value => onChangeRow(value)}
              count={total}
            />
          </Container>
        )}
      </PaginationProvider>
    </>
  );
};

export default Patrimony;
