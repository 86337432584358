import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import { useBranches } from 'providers/BranchesProviders';
import { useCallback, useState, useEffect } from 'react';
import { api } from 'services/api';
import { Department } from 'types/department';
import { Patrimony } from 'types/patrimony';

export interface PatrimonyFilter {
  term: string;
  responsible: string;
  branch_id: number;
  department_id: string;
  is_active: number | string;
}

export function useFetchPatrimonies() {
  const [filter, setFilter] = useState<PatrimonyFilter>({
    term: '',
    responsible: '',
    is_active: '',
    branch_id: 0,
    department_id: 'all',
  });
  const [departments, setDepartments] = useState<Department[]>([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [orderedIndex, sort] = useTableOrder();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [patrimonies, setPatrimonies] = useState<Patrimony[]>([]);
  const [filteredPatrimonies, setFilteredPatrimonies] = useState<Patrimony[]>([]);
  const { branches } = useBranches();

  useEffect(() => {
    setFilteredPatrimonies(patrimonies);
  }, [patrimonies]);

  useEffect(() => {
    api
      .get('hr-departments', {
        params: {
          page: 1,
          rows: 1000,
        },
      })
      .then(response => {
        setDepartments(response.data.items);
      });
  }, []);

  const fetchPatrimonies = useCallback(
    (filter: PatrimonyFilter) => {
      setLoading(true);
      const _filter = {
        ...filter,
        is_active: filter.is_active === '' ? null : filter.is_active,
        branch_id: filter.branch_id === 0 ? null : filter.branch_id,
        department_id: filter.department_id === 'all' ? null : filter.department_id,
      };

      api
        .get(`/patrimonies`, {
          params: { ..._filter, page: page + 1, rows },
        })
        .then(response => {
          setPatrimonies(
            response.data.items.map(item => ({
              ...item,
              is_active: item.is_active ? 'Sim' : 'Não',
              formattedCreatedAt: format(parseISO(item.created_at), 'P', { locale: ptBR }),
              formattedFilial: branches.filter(_item => parseInt(item.branch_id) === _item.id)[0].nome,
            })),
          );
          setTotal(response.data.total);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    },
    [rows, page, branches],
  );

  function handleSort(index: string) {
    const filtered = sort(index, filteredPatrimonies);
    setFilteredPatrimonies(filtered);
  }

  function handleChangeFilter(index: keyof PatrimonyFilter, value: any) {
    setFilter(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  function onChangePage(page: number) {
    setPage(page);
  }

  function onChangeRow(rows: number) {
    setRows(rows);
  }

  return {
    handleSort,
    loading,
    departments,
    orderedIndex,
    filteredPatrimonies,
    fetchPatrimonies,
    handleChangeFilter,
    filter,
    total,
    onChangePage,
    onChangeRow,
  };
}
