import React from 'react';
import { AbcProductPayment } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { Typography } from '@mui/material';

type PaymentItemTableProps = {
  payment: AbcProductPayment;
};

const PaymentItemTable: React.FC<PaymentItemTableProps> = ({ payment }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
            <Typography style={{ fontSize: 11 }} variant="body2">
              {payment[item.id as keyof AbcProductPayment]}
            </Typography>
          </div>
        ))}
    </>
  );
};

export default PaymentItemTable;
