import { Dispatch, SetStateAction, useState } from 'react';
import { Department } from 'types/department';
import * as yup from 'yup';

export type DepartmentValidation = {
  name?: string;
};

type UseDepartmentValidation = [
  DepartmentValidation,
  Dispatch<SetStateAction<DepartmentValidation>>,
  (department: Department) => Promise<void>,
];

export function useDepartmentValidation(): UseDepartmentValidation {
  async function handleValidation(department: Department) {
    const schema = yup.object().shape({
      name: yup.string().required('O nome do departamento é obrigatório'),
    });

    try {
      await schema.validate(department);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<DepartmentValidation>({});
  return [validation, setValidation, handleValidation];
}
