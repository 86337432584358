import React, { FormEvent, useEffect, useRef } from 'react';
import { MenuItem, TextField, styled } from '@mui/material';
import { Patrimony } from 'types/patrimony';
import { PatrimonyValidation } from './validation/patrimonyValidation';
import { Department } from 'types/department';
import SelectBranch from 'components/select-branches/SelectBranch';

const Form = styled('form')({
  rowGap: 15,
  display: 'flex',
  maxWidth: 700,
  flexDirection: 'column',
});

interface PatrimonyFormProps {
  handleValidation(e: FormEvent<HTMLFormElement>): void;
  departments: Department[];
  patrimony: Patrimony;
  handleChange(index: keyof Patrimony, value: any): void;
  validation: PatrimonyValidation;
}

const PatrimonyForm: React.FC<PatrimonyFormProps> = ({
  patrimony,
  departments,
  handleChange,
  handleValidation,
  validation,
}) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    serial_note_number: useRef<HTMLInputElement>(null),
    serial_number: useRef<HTMLInputElement>(null),
    branch_id: useRef<HTMLInputElement>(null),
    department_id: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Form onSubmit={handleValidation}>
      <SelectBranch
        viewAllBranches={false}
        branch_id={parseInt(patrimony?.branch_id)}
        handleChange={value => handleChange('branch_id', value)}
        error={!!validation.branch_id}
        helperText={validation.branch_id}
      />

      <TextField
        select
        error={!!validation.department_id}
        helperText={validation.department_id}
        label="Departamento"
        placeholder="Selecione o departamento"
        value={patrimony.department_id}
        onChange={e => handleChange('department_id', e.target.value)}
        fullWidth
      >
        {departments.map(department => (
          <MenuItem value={department.id} key={department.id}>
            {department.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        autoFocus
        ref={inputs.name}
        value={patrimony?.name}
        onChange={e => handleChange('name', e.target.value)}
        error={!!validation.name}
        helperText={validation.name}
        placeholder="Digite a descrição do patrimônio"
        label="Descrição"
      />

      <TextField
        autoFocus
        ref={inputs.serial_number}
        value={patrimony?.serial_number}
        onChange={e => handleChange('serial_number', e.target.value)}
        error={!!validation.serial_number}
        helperText={validation.serial_number}
        placeholder="Digite o número de série"
        label="Número de série"
      />

      <TextField
        autoFocus
        ref={inputs.serial_note_number}
        value={patrimony?.serial_note_number}
        onChange={e => handleChange('serial_note_number', e.target.value)}
        error={!!validation.serial_note_number}
        helperText={validation.serial_note_number}
        placeholder="Digite o número da NF de compra"
        label="Número da NF de compra"
      />

      <TextField
        autoFocus
        value={patrimony?.responsible}
        onChange={e => handleChange('responsible', e.target.value)}
        placeholder="Digite o nome do responsável"
        label="Responsável"
      />

      <TextField
        autoFocus
        rows={4}
        multiline
        value={patrimony?.observation}
        onChange={e => handleChange('observation', e.target.value)}
        placeholder="Digite a observação"
        label="Observação"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  );
};

export default PatrimonyForm;
