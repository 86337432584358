import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { SaleActivity } from 'types/reports/saleActivity';
import { SaleActivityProvider } from './hooks/useSaleActivity';
import SaleActivityReportFilter from './saleActivityReportFilter';
import SalesActivityReportData from './saleActivityReportData';
import { saleActivityTableTemplate } from './saleActivityTableTemplate';
import SaleActivityListTable from './list/table/saleActivityListTable';
import SaleActivityListModule from './list/module/saleActivityListModule';
import FilterActions from 'components/filter-box/FilterActions';
import { useSelector } from 'store/selector';
import SaleActivityReportFilterBox from './SaleActivityReportFilterBox';

export interface SaleActivityFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SaleActivityReport: React.FC = () => {
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const { h2iApi, windowWidth, isMobile } = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [loading, setLoading] = useState(false);
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<any>(0);
  const [salesActivity, setSalesActivity] = useState<SaleActivity[]>([]);
  const [filterdSalesActivity, setFilteredSalesActivity] = useState<SaleActivity[]>([]);
  const [selectedSaleActivity, setSelectedSaleActivity] = useState<SaleActivity | null>(null);
  const [filter, setFilter] = useState<SaleActivityFilter>({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredSalesActivity(salesActivity);
  }, [salesActivity]);

  function handleChangeFilter(index: keyof SaleActivityFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filterdSalesActivity);
    setFilteredSalesActivity(filtered);
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch(searchText);
  }

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      h2iApi
        .get(`/api/relvendasatividade`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            filtro: searchValue,
          },
        })
        .then(response => {
          if (!response.data.relatividade) {
            setSalesActivity([]);
            return;
          }

          const _salesActivity = response.data.relatividade.map(item => {
            item.formattedTotal = moneyFormat(item.vl_total);
            item.formattedCost = moneyFormat(item.custo);
            item.formattedMc = numberFormat(item.mc);
            item.formattedSpiff = numberFormat(item.spiff);

            return item;
          });

          setSalesActivity(_salesActivity);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, filter],
  );

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <SaleActivityProvider
      value={{
        selectedSaleActivity,
        setSelectedSaleActivity,
        filter,
        handleChangeFilter,
      }}
    >
      {dialogDate && <SaleActivityReportFilter onExited={() => setDialogDate(false)} />}

      {shownReport && (
        <SalesActivityReportData data={salesActivity} branch={selectedBranch} onExited={() => setShownReport(false)} />
      )}
      <Appbar
        title="Vendas por atividade"
        ActionsComponent={
          <>
            {windowWidth < 960 && isMobile && (
              <Button color="primary" variant="contained" onClick={() => setShownReport(true)}>
                Imprimir
              </Button>
            )}
            <FilterActions handleOpenDialog={() => setDialogDate(true)} />
          </>
        }
      />
      <PageHeaderActions
        title="Relação de vendas por atividade"
        description="Gerencie as vendas por atividade"
        ActionComponent={
          <Button color="primary" variant="contained" onClick={() => setShownReport(true)}>
            Imprimir
          </Button>
        }
      />

      <SaleActivityReportFilterBox
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearchInputChange={handleSearchInputChange}
        handleSearchSubmit={handleSearchSubmit}
        loading={loading}
        searchText={searchText}
        setSelectedBranch={setSelectedBranch}
      />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filterdSalesActivity.length === 0 ? (
        <NoData message="Nenhum item para mostrar" />
      ) : (
        <TableContainer tableTemplate={saleActivityTableTemplate}>
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <SaleActivityListTable
                  salesActivity={filterdSalesActivity}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SaleActivityListModule salesActivity={filterdSalesActivity} />
              )}
              <Pagination count={filterdSalesActivity.length} />
            </Container>
          </PaginationProvider>
        </TableContainer>
      )}
    </SaleActivityProvider>
  );
};

export default SaleActivityReport;
