import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import PatrimonyItemModule from './PatrimonyItemModule';
import { Patrimony } from 'types/patrimony';

type PatrimonyListModuleProps = {
  patrimonies: Patrimony[];
};

const PatrimonyListModule: React.FC<PatrimonyListModuleProps> = ({ patrimonies }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {patrimonies.map(patrimony => (
            <PatrimonyItemModule key={patrimony.id} patrimony={patrimony} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PatrimonyListModule;
